import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import PageHeader from './PageHeader'

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu/>
        <PageHeader />
          {this.props.children}
      </div>
    );
  }
}
