import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Popover, Button, Typography } from '@mui/material';
import "../ScheduleHeaderOut.css";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: '#0097E0',
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: '#0097E0',
    },
  }),
  ...(isSelected && isHovered && {
    backgroundColor: '#0097E0',
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: '#0097E0',
    },
  }),
  ...(isHovered && !isSelected && {
    backgroundColor: '#DFF5FF',
    '&:hover, &:focus': {
      backgroundColor: '#DFF5FF',
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB === null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export default function WeekPicker({ value, message, onDateSelect, endPicker, empty, MAWFilter }) {
  const [open, setOpen] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (value) {
      if (endPicker === true) {
        const firstDayOfWeek = dayjs(value).endOf('week');
        setSelectedWeek(firstDayOfWeek.format('MM/DD/YY'));
      }
      else if (endPicker === false){
        const firstDayOfWeek = dayjs(value).startOf('week');
        setSelectedWeek(firstDayOfWeek.format('MM/DD/YY'));
      }
      else {
        setSelectedWeek(null);
      }
    }
  }, [value]);

  const handleOpen = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(open);
    setAnchorEl(null);
  };

  const handleInternalDateSelect = (newValue) => {
    if (onDateSelect) {
      onDateSelect(newValue);
    }
    else{
      if (endPicker === true) {
        const firstDayOfWeek = dayjs(newValue).endOf('week');
        setSelectedWeek(firstDayOfWeek.format('MM/DD/YY'));
      }
      else if (endPicker === false){
        const firstDayOfWeek = dayjs(newValue).startOf('week');
        setSelectedWeek(firstDayOfWeek.format('MM/DD/YY'));
      }
      else {
        setSelectedWeek(null);
      }
    }

  };

  const currentDate = new Date();

  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);

  return (
    <div>
      <Button
        disableRipple
        className="weekcontainer"
        style={{
          // justifyContent: 'space-between',
          height: '36px'
        }}
        onClick={handleOpen}
        variant="outlined"
        size="small"
        sx={{
          textTransform: 'none',
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          borderRadius: '0px',
          fontSize: 15,
          color: 'black',
          fontStyle: 'normal',
          boxShadow: 'none',
          transition: 'background-color 0s, color 0s',
          minWidth: '0px',
          '&:hover': {
            backgroundColor: '#f6f6f6',
            border: '1px solid transparent',
            // borderTop: '1px solid #8C8C8C',
            // borderBottom: '1px solid #8C8C8C',
          }
        }}
      >
        {
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_835_48548"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_835_48548)">
              <path d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z" fill="#0097E0"/>
            </g>
          </svg>
        }
        <p className= {MAWFilter ? 'roboto-15-400' : 'roboto-15-400 hide-class'} style={{ paddingLeft: '8px', color: empty === false ? '#212121' : '#BFBFBF' }}>{empty === false ? selectedWeek : 'MM/DD/YY'}</p>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{width:'385px'}}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={['year', 'month', 'day']}
            value={value}
            onChange={(newValue) => handleInternalDateSelect(newValue)}
            showDaysOutsideCurrentMonth
            displayWeekNumber
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) => ({
                selectedDay: value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
          />
        </LocalizationProvider>
        <div style={{ padding: '8px', flexDirection: 'column'}}>
        {message && (
        <div className='roboto-error' style={{ gap: '8px', flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: '8px'}}>
          <svg style={{paddingTop: '5px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 16C3.58155 16 0 12.4185 0 8C0 3.58155 3.58155 0 8 0C12.4185 0 16 3.58155 16 8C16 12.4185 12.4185 16 8 16Z" fill="#FF4949"/>
            <path d="M7.9705 2.85352C7.31782 2.85352 6.82397 3.19413 6.82397 3.74429V8.79045C6.82397 9.34119 7.31775 9.68065 7.9705 9.68065C8.60725 9.68065 9.11702 9.32698 9.11702 8.79045V3.74429C9.11695 3.20719 8.60725 2.85352 7.9705 2.85352Z" fill="white"/>
            <path d="M7.97042 10.8174C7.34335 10.8174 6.83301 11.3277 6.83301 11.9553C6.83301 12.5818 7.34335 13.0921 7.97042 13.0921C8.59748 13.0921 9.10725 12.5818 9.10725 11.9553C9.10719 11.3277 8.59748 10.8174 7.97042 10.8174Z" fill="white"/>
          </svg>
          {message}
        </div> )}
        {/* <div style= {{display: 'flex', justifyContent: 'flex-end'}}>
          <button  className='secondary-button roboto-15-500 blue-text' onClick={handleClose}>OK</button>
        </div> */}
        </div>
      </Popover>
    </div>
  );
}

