import React from 'react';

const PriorityCell = (props) => {
  const { dataItem } = props;
  if (dataItem.isPriority) {
    return (
    <td style={{padding: "0px", paddingLeft: '7px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#FF4949">
            <path d="M200-120v-680h360l16 80h224v400H520l-16-80H280v280h-80Zm300-440Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z"/>
        </svg>
    </td>
    
    );
  } else {
    return <td></td>;
  }
};

export default PriorityCell;