import React, { useState, useEffect } from 'react'; 
import { Dialog } from '@progress/kendo-react-dialogs';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import './SavedFiltersModal.css';

const SavedFiltersModal = ({ onClose, handleShowMessage, setSelectedCodeItemsOptions, setCodeItemsFiltersSelected }) => {
  const [savedFilters, setSavedFilters] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editedTitle, setEditedTitle] = useState(""); 

  const handlApplyClick = (filter, e) => {

    e.stopPropagation();

    const appliedFilters = Object.keys(filter.filters).flatMap(description => {
      return filter.filters[description].map(value => {
        const codeDetailsForDescription = filter.codeDetails[description] || [];
        return codeDetailsForDescription.map(detail => ({
          Value: value,
          codeStart: detail.codeStart,
          codeLength: detail.codeLength,
          fiCodeItem: detail.fiCodeItem,
          description: description
        }));
      });
    }).flat();
    
    //update filters selected in modal and chips
    setCodeItemsFiltersSelected(appliedFilters);
    setSelectedCodeItemsOptions(appliedFilters);
    onClose();

  };  

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleEditClick = (index, currentTitle, e) => {
    e.stopPropagation();
    setEditMode(index);
    setEditedTitle(currentTitle || ''); 
  };

  const handleSaveTitle = (index, e) => {
    e.stopPropagation();
    const updatedFilters = [...savedFilters];
    updatedFilters[index].title = editedTitle;
    setSavedFilters(updatedFilters);
    localStorage.setItem('savedFilters', JSON.stringify(updatedFilters));
    setEditMode(null);
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditMode(null);
  };

  const handlePanelToggle = (event) => {    
    const expandedItems = event.expandedItems.map(item => parseInt(item.replace('.', ''), 10));
    setExpandedPanels(expandedItems); 
  };
  const handleDelete = (filterIndex, e) => {
    e.stopPropagation();
    const updatedFilters = savedFilters.filter((_, index) => index !== filterIndex);      
    localStorage.setItem('savedFilters', JSON.stringify(updatedFilters));

    getSavedCodeitemsFilters();
    handleShowMessage("Code string Filter has been deleted from localStorage", "success");
  };

  const getSavedCodeitemsFilters = () => {
    const storedFilters = localStorage.getItem('savedFilters');
    if (storedFilters) {
      setSavedFilters(JSON.parse(storedFilters));
    }
  };

  useEffect(() => {
    getSavedCodeitemsFilters();
  }, []);

  return (
    <Dialog onClose={onClose} className='modalContainer'>
      <div className="modal-header">
        <h2 className='roboto-23-700 ml-2'>Saved filters</h2>
        <Button onClick={onClose} className="x-button">
          <span className="material-symbols-outlined x-icon mt-1">
            close
          </span>
        </Button>
      </div>

      <PanelBar
        className="roboto-17-700"
        onSelect={(event) => handlePanelToggle(event)}
      >
        {savedFilters.map((filter, index) => (
          <PanelBarItem key={index}
            title={
              <div className="panelbar-header">
                <Button className="expand-button">
                  <span className="material-symbols-outlined modalSave_icon-edit">
                    {expandedPanels?.includes(index) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                  </span>
                </Button>

                {editMode === index ? (
                  // Edit Section title
                  <>
                    <input
                      type="text"
                      value={editedTitle}
                      onChange={handleTitleChange}
                      onClick={(e) => e.stopPropagation()}
                      className="edit-title-input"
                    />
                    <div className="button-group gap10">
                      <Button className="primary-button roboto-15-500" onClick={(e) => handleSaveTitle(index, e)}>Save</Button>
                      <Button className="secondary-button roboto-15-500 blue-text" onClick={handleCancelEdit}>Cancel</Button>
                    </div>
                  </>
                ) : (
                  <>
                    <span>{filter.title || ''}</span>
                    <div className="button-group">
                      <Button className="modalSave_icon-edit-btn icon-blue" onClick={(e) => handleEditClick(index, filter.title, e)}>
                        <span className="material-symbols-outlined modalSave_icon-edit">edit_square</span>
                      </Button>
                      <button className="icon-trash" onClick={(e) => handleDelete(index, e)}>
                        <img className="icon-image" alt="Edit Trash" src="/edit-trash-blue.png" />
                      </button>
                      <Button className="apply-button savedModal_apply-Btn" onClick={(e) => handlApplyClick(filter, e)}>
                        Apply
                      </Button>
                    </div>
                  </>
                )}
              </div>
            }
          >
          <ul className="mb-3">
            {Object.keys(filter.filters).map((section, idx) => {
              
              const codeDetailsForSection = filter.codeDetails[section];              
              const codeDetail = codeDetailsForSection ? codeDetailsForSection[0] : null;

              return (
                <li key={idx} className="d-block w-100 fs-15">
                  <div className="modalSave_checkList">
                    <span>                      
                      {codeDetail ? codeDetail.fiCodeItem : idx + 1}. {section}
                    </span>
                  </div>
                  {filter.filters[section].map((option, optionIdx) => (
                    <div key={optionIdx} className="modalSave_checkitem mb-2">
                      <input className="k-checkbox k-checkbox-md k-rounded-md check-border" type="checkbox" readOnly={true} checked={true} />
                      <span className="check-margin">{option}</span>
                    </div>
                  ))}
                </li>
              );
            })}
          </ul>

          </PanelBarItem>
        ))}
      </PanelBar>
    </Dialog>
  );
};

export default SavedFiltersModal;
