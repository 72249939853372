import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { truncateEmail } from '../Utilities/utils';
import { useLocalStorage } from 'usehooks-ts';
import axios from '../axiosConfig';

const PROFILE_IMAGE = 'PROFILE_IMAGE';
const PROFILE_IMAGE_DATA = 'PROFILE_IMAGE_DATA';

const useProfileData = (fetchProfileImage = true) => {//false to not obtain userImage
  const [token, setToken] = useState();
  const [profileImage, setProfileImage] = useLocalStorage(PROFILE_IMAGE, '/DefaultProfile.png');
  const [profileImageData, setProfileImageData] = useLocalStorage(PROFILE_IMAGE_DATA, null);
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const { instance, accounts } = useMsal();
  const firstAccount = accounts[0];

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: firstAccount
        });

        if (response.accessToken ) {
          const token = response.accessToken;

          if (fetchProfileImage && profileImageData === null) {
            const graphEndpoint = 'https://graph.microsoft.com/v1.0/me/photo/$value';
            const imageResponse = await axios.get(graphEndpoint, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob',
            });

            if (imageResponse && imageResponse.data) {
              const reader = new FileReader();
              reader.readAsDataURL(imageResponse.data);
              reader.onload = () => {
                setProfileImage(reader.result);
                setProfileImageData(imageResponse);
              };
            }
          }
          setToken(firstAccount.idToken);
          setuserName(response.account.name);
          setuserEmail(truncateEmail(response.account.username,50));
        }
      } catch (error) {
        //Error found when the account has never had an profile image
        setProfileImage('/DefaultProfile.png');
        setProfileImageData(null);
      }
    };

    fetchProfileData();
  }, [instance, firstAccount, fetchProfileImage, profileImageData, setProfileImage, setProfileImageData]);

  return { token, profileImage, userName, userEmail, profileImageData };
};

export default useProfileData;
