import React, { useEffect, useState, useRef } from 'react';
import axios from '../axiosConfig';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import '@progress/kendo-theme-default/dist/all.css';
import './GeneralGridStyle.css';
import CustomLockedCell from './customizedColumns/CustomLockedCell'
import SidePanelNewO from './SidePanelNewO';
import useProfileData from '../services/ProfileDataService';
import CustomFilter from '../components/customizedFilters/CustomFilter';
import Loader from './Loader';
import Toast from './ToastNotification';
import { truncateEmail } from '../Utilities/utils';
import { useMsal } from "@azure/msal-react";
import Modal from './Modal';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import LogOutService from '../services/LogOutService';
import { getCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import NewOrdersCellStyle from './customizedColumns/NewOrdersCellStyle';

import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { TextBox, NumericTextBox, TextArea, Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";


const minValueValidator = (value) => (value >= 0 ? "" : "The value must be 0 or higher");

const NonNegativeNumericInput = (fieldRenderProps) => {
  const [toast, setToast] = useState(false);
  const [idtoast, setIdtoast] = useState(1);
  const { disabled2, validationMessage, visited, id, valid, label, ...others } = fieldRenderProps;

  useEffect(() => {
    if (fieldRenderProps.modified == true && fieldRenderProps.value < 0) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'The value must be 0 or higher', type: 'error' });
    }
  }, [fieldRenderProps.validationMessage != ""]);

  return (
    <>
      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <Label editorId={id} editorValid={valid} className={"k-form-label roboto-15-500"}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <NumericTextBox spinners={disabled2} {...others} />
      </div>
    </>
  );
};

export const requiredValidator = (value) =>
  value ? "" : "Error: This field is required.";

export const FormTextArea = (fieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, disabled, optional, ...others } = fieldRenderProps;
  const max = 500;

  return (
    <FieldWrapper>
      <Label editorId={id} className={"k-form-label roboto-15-500"}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <TextArea
          style={{ height: '65px' }}
          valid={valid}
          id={id}
          disabled={disabled}
          maxLength={max}
          rows={10}
          {...others}
        />
      </div>
    </FieldWrapper>
  );
};
const FormDatePicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    value,
    ...others
  } = fieldRenderProps;
  // const showValidationMessage = touched && validationMessage;
  const labelId = label ? `${id}_label` : "";

  // Date validation with the local storage
  const fixedDate = new Date('Wed Dec 31 1969 18:00:00 GMT-0600 (GMT-06:00)');
  const truncateTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };
  const isSameDate = (date1, date2) => {
    if (!date1 || !date2) return false; // Check if either date is null or undefined
    return truncateTime(date1).getTime() === truncateTime(date2).getTime();
  };
  const defaultValue = value == null || isSameDate(value, fixedDate) ? null : value;   // Check if value is null or undefined, or if the dates are the same

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className="k-form-label roboto-15-500"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap roboto-15-500"}>
        <DatePicker
          ariaLabelledBy={labelId}
          valid={valid}
          id={id}
          disabled={disabled}
          value = {defaultValue}
          {...others}
        />
        {/* {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )} */}
      </div>
    </FieldWrapper>
  );
};

const EditForm = props => {
  const {
    cancelEdit,
    onSubmit,
    item,
    ...other
  } = props;
  return (
    <FormElement>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <FieldWrapper>
          <Field width={100} name={"draftingHrs"} component={NonNegativeNumericInput} label={"Drafting HRS"} validator={minValueValidator} />
        </FieldWrapper>
        <Field
          id={"gdate"}
          name={"gdate"}
          label={"Earliest drafting completion"}
          component={FormDatePicker}
        // validator={requiredValidator}
        />
        <Field
          id={"endate"}
          name={"endate"}
          label={"Earliest Eng. completion"}
          component={FormDatePicker}
        // validator={requiredValidator}
        />
      </div>
      <FieldWrapper>
        <Field name={"ProductName"} component={FormTextArea} label={"Additional assembly time"} />
      </FieldWrapper>
      {/* </FormElement> */}
      <div style={{ marginTop: '24px', marginBottom: '-24px' }}>
        <DialogActionsBar layout="end">
          <Button className='secondary-button blue-text roboto-15-500'
            onClick={cancelEdit}>
            Close
          </Button>
          <Button
            className='primary-button roboto-15-500'
            type={"submit"}
            themeColor={"primary"}
          // disabled={!submit}
          >
            Save
          </Button>
        </DialogActionsBar>
      </div>
    </FormElement>
  );

};

const NewOrdersGrid = ({ columns = [], dateRange,
  filter, setFilter,
  prevFilter, setPrevFilter,
  currentFilter, setCurrentFilter, onRemoveAll,
  setRemoveFilter, dropdownFilters, setDropdownFilters, removeFilter, disabled,
  exportExcel, setExportExcel, CodeStringFilters, setCodeitemsChipsData, setScheduleData
}) => {
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const storedCurrentPage = localStorage.getItem('currentPageNewOrdView');
  const storedPage = localStorage.getItem('pageNewOrdView');

  const [pageSize, setPageSize] = useState(storedPage ? JSON.parse(storedPage).take : 50);
  const [sort, setSort] = useState([{ field: "SO", dir: "desc" }]);
  const [page, setPage] = useState(
    {
      skip: storedPage ? JSON.parse(storedPage).skip : 0,
      take: storedPage ? JSON.parse(storedPage).take : pageSize
    });


  const { token } = useProfileData(false);

  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? JSON.parse(storedCurrentPage) : 1);
  const [totalRows, setTotalRows] = useState(0);
  const [department, setDepartment] = useState(getCurrentLine().ProdFamilyId);

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const { instance } = useMsal();
  const viewAlias = 2;

  const fetchData = async () => {

    try {
      setLoading(true);
      const storedStartDate = localStorage.getItem('startDate');
      const storedEndDate = localStorage.getItem('endDate');
      if (token) {
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent(sort?.[0]?.field || 'SO')}`,
          {
            prodFamilyId: department,
            dateColumn: 'BuildWeek',
            sort: sort?.[0]?.dir || 'des',
            skip: page.skip,
            take: page.take,
            startDate: null,
            endDate: null,
            filter: JSON.stringify(filter, null, 2),
            viewAlias: viewAlias,
            CodeStringFilter : JSON.stringify(CodeStringFilters, null, 2),
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const usersWithIndex = response.data.items.map((user, index) => {
          const orderDetailId = user.orderDetailId;
          const orderDetail = newOrdersExInfo.find(detail => detail.orderDetailId === orderDetailId);
          const fixedDate = new Date('Wed Dec 31 1969 18:00:00 GMT-0600 (GMT-06:00)');

          const truncateTime = (date) => {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
          };
          const isSameDate = (date1, date2) => {
            if (!date1 || !date2) return false; // Check if either date is null or undefined
            return truncateTime(date1).getTime() === truncateTime(date2).getTime();
          };
          const currentGDate = orderDetail ? new Date(orderDetail.gdate) : null;
          const currentEnDate = orderDetail ? new Date(orderDetail.endate) : null;

          const adjustDate = (date) => {
            if (date == null) return null; // If the date is null, return null
            if (isSameDate(date, fixedDate)) return null; // If the date is the fixed date, return null
            if (isSameDate(date, fixedDate)) return null; 
            return date; // Otherwise, return the date
          };

          const adjustedGDate = adjustDate(currentGDate);
          const adjustedEnDate = adjustDate(currentEnDate);

          return {
            ...user,
            rowIndex: index + 1 + page.skip,
            gdate: adjustedGDate, 
            endate: adjustedEnDate,
            ProductName: orderDetail ? orderDetail.ProductName : ''
          };
        });

        setScheduleData({
          TotalRows: response.data.TotalRows,
          Items: usersWithIndex,
        });
        setUsers(usersWithIndex);
        setTotalRows(response.data.totalRows);
        setLoading(false);     

      } 
      
    } catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if (error.response.status === 401) LogOutService(instance);

      } else {
        setToast({ showToast: true, message: error.message, type: 'error' });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('filtersGeneralView', JSON.stringify(filter, null, 2));
    const sotredFilters = JSON.stringify(filter?.filters, null, 2);

    if (sotredFilters)
      localStorage.setItem('dropDownFiltersGeneralView', sotredFilters);
  }, [filter]);

  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setDepartment(newCurrentLine.ProdFamilyId);
    });
    return () => {
      unsubscribe();
    };
  }, []);



  useEffect(() => {
    setPage({ skip: 0, take: pageSize });
    setCurrentPage(1);
  }, [dateRange, sort, filter]);

  const [filtro, setFiltro] = useState(null)

  const filterConfig = {
    text: "text",
    number: "numeric",
    boolean: "boolean",
    date: "date",
    dropdown: {
      filterCell: (props) => {
        const { field } = props;
        return (
          <CustomFilter
            {...props}
            data={users}
            field={field}
            currentFilterField={field}
            onSelectionChange={handleCustomFilterChange}
            onRemoveAll={onRemoveAll}
            selectedOptions2={currentFilter}
            selectedFilter={filtro}
            removeFilter={removeFilter}
            prevFilter={prevFilter}
            filter={dropdownFilters}  
            viewOption={viewAlias}          
          />
        );
      }
    }
  };

  const handleCustomFilterChange = (selectedOptions, currentFilterField) => {

    setFilter((prevFilter) => {
      if (!prevFilter) {
        // if there are not a existing filter creatte a new one
        return {
          logic: "or",
          filters: [
            ...selectedOptions.map((option) => ({
              field: currentFilterField,
              operator: "eq",
              value: option,
            })),
          ],
        };
      } else {
        // if there already exist a filter add current selection
        const updatedFilters = [
          ...prevFilter.filters.filter((filter) => filter.field !== currentFilterField),
          ...selectedOptions.map((option) => ({
            field: currentFilterField,
            operator: "eq",
            value: option,
          })),
        ];

        return {
          ...prevFilter,
          filters: updatedFilters,
        };
      }
    });
    setFiltro(currentFilterField);
    setCurrentFilter(selectedOptions);
    localStorage.setItem('currentFilterGeneralView', JSON.stringify(selectedOptions));
    setRemoveFilter(false);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    allFields.unshift(currentFilterField);
    const allFields2 = [...new Set(allFields)];
    setPrevFilter(allFields2);
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify(allFields2));
    setDropdownFilters(filter?.filters);
  };


  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const newPage = {
      skip: event.page.skip,
      take: targetEvent.value === 'All' ? totalRows : event.page.take,
    };

    setPage(newPage);
    setCurrentPage(newPage.skip / pageSize + 1);

    localStorage.setItem('pageNewOrdView', JSON.stringify(newPage));
    localStorage.setItem('currentNewOrdView', JSON.stringify(newPage.skip / pageSize + 1));
  };

  const handleFilterChange = (event) => {
    setFilter(event.filter);
  };

  const filteredUsers = filterBy(users, filter);
  const sortedUsers = orderBy(filteredUsers, sort);

  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns], [sortedUsers]);

  const [selectedColumnFields, setSelectedColumnFields] = useState(columns.map(column => column.field));

  useEffect(() => {
    setSelectedColumnFields(columns.map(column => column.field));
  }, [columns]);

  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState();
  const [data, setData] = useState(sortedUsers);

  const enterEditSidePanel = async (item) => {

    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };    
    setOpenSidePanel(true);
    setEditItem(updatedItem);

  };

  const enterEdit = async (item) => {

    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenForm(true);    
    setEditItem(updatedItem);

  };

  const handleSubmit = (event) => {
    let newData = data.map((item) => {
      if (event.item === item.item) {
        item = {
          ...event,
        };
      }
      return item;
    });
    setData(newData);
    setOpenForm(false);
  };

  const showToastError = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'An unexpected error occurred', type: 'error' });
  };
  const showToastSuccess = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order updated successfully', type: 'success' });
  };

  const storedCurrentNewOrders = localStorage.getItem('newOrdersExtrFields');
  const [newOrdersExInfo, setNewOrdersExInfo] = useState(storedCurrentNewOrders ? JSON.parse(storedCurrentNewOrders) : []);
  const { accounts } = useMsal();

  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast + 1);


    fetchData();
  }, [token, page.skip, page.take, sort, dateRange, filter, department, newOrdersExInfo, CodeStringFilters]);

  useEffect(() => {
    const formattedCodeItems = CodeStringFilters.reduce((acc, item) => {
      acc[item.description] = acc[item.description] || [];
      acc[item.description].push(`${item.Value}`);
      return acc;
    }, {});

    setCodeitemsChipsData(formattedCodeItems);
  }, [CodeStringFilters]);

  const handleDraftHoursEstimate = async (values) => {  
    const gdate = new Date(values.gdate);
    const endate = new Date(values.endate);
    const fixedDate = new Date('Wed Dec 31 1969 18:00:00 GMT-0600 (GMT-06:00)');
    
    const newOrderDetail = {
      orderDetailId: values.orderDetailId,
      gdate: values.gdate ? gdate: fixedDate,
      endate: values.endate ? endate: fixedDate,
      ProductName: values.ProductName
    };

    try {
      const updateUrl = `/updateDraftHoursEstimate?&orderDetailId=${encodeURIComponent(values.orderDetailId)}&author=${encodeURIComponent(truncateEmail(accounts[0].username, 50))}&totalHrs=${encodeURIComponent(values.draftingHrs)}`;

      const response = await axios.put(
        updateUrl,
        null,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        setNewOrdersExInfo((prevOrders) => {
          // if (!Array.isArray(prevOrders)) {
          //   prevOrders = [];
          // }

          const orderDetailExists = prevOrders.some(order => order.orderDetailId === newOrderDetail.orderDetailId);

          if (orderDetailExists) {
            
            return prevOrders.map(order => //update array if order already exists
              order.orderDetailId === newOrderDetail.orderDetailId ? newOrderDetail : order
            );
          } else {
            return [...prevOrders, newOrderDetail];
          }

        });
        
        setNewOrdersExInfo((updatedOrders) => {          
          localStorage.setItem('newOrdersExtrFields', JSON.stringify(updatedOrders, null, 2));
          return updatedOrders;
        });
        
        showToastSuccess();        
      }

    } catch (error) {
      showToastError();
    };
    
    setOpenForm(false);    
    setEditItem();
  };

  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleCancelEdit = () => {
    setOpenForm(false);
    setOpenSidePanel(false);

    setEditItem();
  };

  const MyCustomLockedCell = (props) => (
    <CustomLockedCell {...props} enterEdit={enterEdit} type={'edit'} />
  );

  const handleSortChange = (e) => {
    setSort(e.sort || []);
  };

  const _export = React.useRef(null);
  const _grid = React.useRef();

  const _formattedDate = (() => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  })();

  const excelExport = () => {
    if (_export.current !== null && exportExcel === true) {

      const today = new Date();
      _export.current.save(users);
    }
  };

  useEffect(() => {
    excelExport();
    setExportExcel(false);
  }, [exportExcel === true]);


  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 270);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 270;
      setGridHeight(newGridHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  // Columns export
  const exportColumns = columns.map((col, index) => (
    <ExcelExportColumn
      key={index}
      field={col.field}
      title={col.title}
    />
  ));
  // Columns export

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <Loader></Loader>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
      />

      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}

      <div style={{ height: gridHeight, marginBottom: '15px', position: 'relative' }}>
        <ExcelExport data={(users || [])} ref={_export} fileName={`newOrders_${_formattedDate}.xlsx`}>
          {exportColumns}
        </ExcelExport>
        <Grid
          className='roboto-15-400'
          data={(users || [])}
          skip={page.skip}
          take={page.take}
          total={totalRows}
          pageable={{
            buttonCount: 4,
            type: "input",
            pageSizes: [50, 150, 200, totalRows].filter(num => num <= totalRows),
            pageSize: pageSize,
          }}
          onPageChange={pageChange}
          sortable={true}
          filterable={true}
          filter={filter}
          onFilterChange={handleFilterChange}
          resizable
          reorderable
          sort={sort}
          onSortChange={handleSortChange}
          style={{
            borderRadius: '8px',
            display: 'flex',
            margin: '15px',
            height: 'inherit'
          }}
          ref={_grid}
        >
          <GridColumn
            className='roboto-15-400'
            reorderable={false}
            sortable={false}
            filterable={false}
            editable={false}
            field="rowIndex"
            title="#"
            width="40px"
          />
          {selectedColumns
            .filter((column) => column.visible)
            .map((column) => {
              return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    format="{0:d}"
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={(props) => (
                      <NewOrdersCellStyle
                        {...props} enterEdit={enterEditSidePanel}
                      />
                    )}
                  />
                );
              }
            )}
          <GridColumn
            filterable={false}
            editable={false}
            width="40px"
            locked={true}
            cell={MyCustomLockedCell}
          />
        </Grid>
        {/* </ExcelExport> */}
      </div>

      {openSidePanel && (
        <SidePanelNewO
          cancelEdit={handleCancelEdit}
          item={editItem}
          disabled={true}          
        />
      )}

      {/* {openForm && (
        <EditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )} */}
      {openForm && editItem && editItem.orderDetailId &&(
        <Dialog
          onClose={handleCancelEdit}
          height={345}
          width={536}
        >
          <div style={{ padding: '8px' }} className='roboto-15-400'>
            <div className='roboto-19-700' style={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <p>{`Edit order SO: ${editItem.so}`}</p>
              {`ESOI: ${editItem.esoi}`}
              <Button
                onClick={handleCancelEdit}
                style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
              </Button>
            </div>
            <Form
              initialValues={editItem}
              onSubmit={handleDraftHoursEstimate}
              render={(formRenderProps) => (
                <EditForm
                  formRenderProps={formRenderProps}
                  item={editItem}
                  cancelEdit={handleCancelEdit}
                // disabled = {disabled}
                />
              )}
            />
          </div>
        </Dialog>
      )}

    </div>
  );
}

export default NewOrdersGrid;