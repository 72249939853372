const storedCurrentLine = localStorage.getItem('currentLine');

let CurrentLine = {
  ProdFamilyId: storedCurrentLine ? JSON.parse(storedCurrentLine).ProdFamilyId : 21,
  ProdFamilyName: storedCurrentLine ? JSON.parse(storedCurrentLine).ProdFamilyName : 'Rooftop',  
  PlantProdFamilyId: storedCurrentLine ? JSON.parse(storedCurrentLine).PlantProdFamilyId : 1,  
};

const subscribers = new Set();

export const getCurrentLine = () => CurrentLine;


export const setCurrentLine = ({ ProdFamilyId, ProdFamilyName, PlantProdFamilyId }) => {
  CurrentLine = { ProdFamilyId, ProdFamilyName, PlantProdFamilyId };
  
  subscribers.forEach(subscriber => subscriber(CurrentLine));
};

// function to suscribe to changes
export const subscribeToCurrentLineChanges = (callback) => {
  subscribers.add(callback);
  
  // cancel suscription
  return () => {
    subscribers.delete(callback);
  };
};
