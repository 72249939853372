import React from 'react';
import TabComponentChangeOrders from '../components/TabComponentChangeOrders';

const ChangeOrders = () => {
  return (
    <div style={{marginTop: '10px'}}>
      <TabComponentChangeOrders></TabComponentChangeOrders>
    </div>
  );
};

export default ChangeOrders;