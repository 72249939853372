import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler} from 'reactstrap';
import { GetCurrentRoute } from '../services/GetCurrentRoute';
import { Popup } from "@progress/kendo-react-popup";
import useProfileData from '../services/ProfileDataService';
import './NavMenu.css'; 
import { PageLayout } from './Authentication/PageLayout';
import { useNavigate  } from 'react-router-dom';
import { getCurrentLine, setCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import axios from '../axiosConfig';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import useEmployeeRole from '../Utilities/EmployeeRole';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const storedCurrentPage = localStorage.getItem('currentPage');
  const currentPage = GetCurrentRoute();
  const {userName, profileImage} = useProfileData();
  const [show, setShow] = useState(false);
  const anchor = useRef(null);
  const [prodFamilyIds, setProdFamilyIds] = useState([]);
  const [prodFamilyNames, setProdFamilyNames] = useState([]);
  const [plantProdFamilyIds, setPlantProdFamilyIds] = useState([]);
  const [filterOptions, setFilterOptions] = useState([])
  const { token } = useProfileData(false);
  const { employeeRole, employeeProdFamilies } = useEmployeeRole();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (show && anchor.current && !anchor.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [show]);

  useEffect(() => {
    setShow(false);
  }, []);
  
  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (show && anchor.current && !anchor.current.contains(event.target)) {
        setShow(false);
      }
    };
 
    document.body.addEventListener('click', handleOutsideClick);
 
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [show]);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  // Button icons
  const calendarsvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
    fill={currentPage === '' ? 'primary-nav-button' : 'secondary-nav-button'}>
        <path d="M7.63818 9.5C7.28818 9.5 6.99235 9.37917 6.75068 9.1375C6.50902 8.89583 6.38818 8.6 6.38818 8.25C6.38818 7.9 6.50902 7.60417 6.75068 7.3625C6.99235 7.12083 7.28818 7 7.63818 7C7.98818 7 8.28402 7.12083 8.52568 7.3625C8.76735 7.60417 8.88818 7.9 8.88818 8.25C8.88818 8.6 8.76735 8.89583 8.52568 9.1375C8.28402 9.37917 7.98818 9.5 7.63818 9.5ZM2.88818 11.5C2.61318 11.5 2.37777 11.4021 2.18193 11.2063C1.9861 11.0104 1.88818 10.775 1.88818 10.5V3.5C1.88818 3.225 1.9861 2.98958 2.18193 2.79375C2.37777 2.59792 2.61318 2.5 2.88818 2.5H3.38818V1.5H4.38818V2.5H8.38818V1.5H9.38818V2.5H9.88818C10.1632 2.5 10.3986 2.59792 10.5944 2.79375C10.7903 2.98958 10.8882 3.225 10.8882 3.5V10.5C10.8882 10.775 10.7903 11.0104 10.5944 11.2063C10.3986 11.4021 10.1632 11.5 9.88818 11.5H2.88818ZM2.88818 10.5H9.88818V5.5H2.88818V10.5ZM2.88818 4.5H9.88818V3.5H2.88818V4.5Z"/>
    </svg>
  );

  const referencesvg = (
    <svg width="11" height="12" viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg"
    fill={currentPage === 'referenceorders' ? 'primary-nav-button' : 'secondary-nav-button'}>
      <path d="M9.54561 11.5L8.19561 10.15C8.01227 10.2583 7.82061 10.3438 7.62061 10.4062C7.42061 10.4688 7.21227 10.5 6.99561 10.5C6.37061 10.5 5.83936 10.2812 5.40186 9.84375C4.96436 9.40625 4.74561 8.875 4.74561 8.25C4.74561 7.625 4.96436 7.09375 5.40186 6.65625C5.83936 6.21875 6.37061 6 6.99561 6C7.62061 6 8.15186 6.21875 8.58936 6.65625C9.02686 7.09375 9.24561 7.625 9.24561 8.25C9.24561 8.46667 9.21436 8.675 9.15186 8.875C9.08936 9.075 9.00394 9.26667 8.89561 9.45L10.2456 10.8L9.54561 11.5ZM6.99561 9.5C7.34561 9.5 7.64144 9.37917 7.88311 9.1375C8.12477 8.89583 8.24561 8.6 8.24561 8.25C8.24561 7.9 8.12477 7.60417 7.88311 7.3625C7.64144 7.12083 7.34561 7 6.99561 7C6.64561 7 6.34977 7.12083 6.10811 7.3625C5.86644 7.60417 5.74561 7.9 5.74561 8.25C5.74561 8.6 5.86644 8.89583 6.10811 9.1375C6.34977 9.37917 6.64561 9.5 6.99561 9.5ZM9.24561 5H8.24561V2.5H7.24561V4H2.24561V2.5H1.24561V9.5H3.74561V10.5H1.24561C0.970605 10.5 0.735189 10.4021 0.539355 10.2063C0.343522 10.0104 0.245605 9.775 0.245605 9.5V2.5C0.245605 2.225 0.343522 1.98958 0.539355 1.79375C0.735189 1.59792 0.970605 1.5 1.24561 1.5H3.33311C3.42477 1.20833 3.60394 0.96875 3.87061 0.78125C4.13727 0.59375 4.42894 0.5 4.74561 0.5C5.07894 0.5 5.37686 0.59375 5.63936 0.78125C5.90186 0.96875 6.07894 1.20833 6.17061 1.5H8.24561C8.52061 1.5 8.75602 1.59792 8.95186 1.79375C9.14769 1.98958 9.24561 2.225 9.24561 2.5V5ZM4.74561 2.5C4.88727 2.5 5.00602 2.45208 5.10186 2.35625C5.19769 2.26042 5.24561 2.14167 5.24561 2C5.24561 1.85833 5.19769 1.73958 5.10186 1.64375C5.00602 1.54792 4.88727 1.5 4.74561 1.5C4.60394 1.5 4.48519 1.54792 4.38936 1.64375C4.29352 1.73958 4.24561 1.85833 4.24561 2C4.24561 2.14167 4.29352 2.26042 4.38936 2.35625C4.48519 2.45208 4.60394 2.5 4.74561 2.5Z"/>
    </svg>    
  );

  const worksvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" 
    fill={currentPage === 'my-work' ? 'primary-nav-button' : 'secondary-nav-button'}
    >
      <g mask="url(#mask0_616_1418)">
        <path d="M2.5 10.5C2.225 10.5 1.98958 10.4021 1.79375 10.2063C1.59792 10.0104 1.5 9.775 1.5 9.5V4C1.5 3.725 1.59792 3.48958 1.79375 3.29375C1.98958 3.09792 2.225 3 2.5 3H4.5V2C4.5 1.725 4.59792 1.48958 4.79375 1.29375C4.98958 1.09792 5.225 1 5.5 1H7.5C7.775 1 8.01042 1.09792 8.20625 1.29375C8.40208 1.48958 8.5 1.725 8.5 2V3H10.5C10.775 3 11.0104 3.09792 11.2063 3.29375C11.4021 3.48958 11.5 3.725 11.5 4V9.5C11.5 9.775 11.4021 10.0104 11.2063 10.2063C11.0104 10.4021 10.775 10.5 10.5 10.5H2.5ZM5.5 3H7.5V2H5.5V3ZM10.5 7.5H8V8.5H5V7.5H2.5V9.5H10.5V7.5ZM6 7.5H7V6.5H6V7.5ZM2.5 6.5H5V5.5H8V6.5H10.5V4H2.5V6.5Z"/>
      </g>
    </svg>
  );

  // const dashboardsvg = (
  //   <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill={currentPage === 'dashboard' ? 'white' : '#0097E0'}>
  //     <path  d="M6.98811 6.46484C6.7839 6.46484 6.61736 6.63364 6.61736 6.84163C6.61736 7.04961 6.7839 7.21841 6.98811 7.21841C7.19308 7.21841 7.35887 7.04961 7.35887 6.84163C7.35887 6.63364 7.19308 6.46484 6.98811 6.46484Z"/>
  //     <g mask="url(#mask0_616_1424)">
  //       <path d="M9.78218 5.48156L7.47324 7.41823C7.15448 7.6865 6.67898 7.6443 6.41146 7.32554C6.14394 7.00678 6.18614 6.53203 6.50415 6.26452L8.81384 4.32709C9.1326 4.05882 9.60811 4.10102 9.87562 4.41978C10.1431 4.73854 10.1009 5.21404 9.78218 5.48156ZM12.3826 9.51995C12.7323 8.81988 12.95 8.0422 13.0005 7.21854H12.2635V6.46497H12.9983C12.947 5.64132 12.7323 4.86363 12.3834 4.16206L11.7451 4.5298L11.3683 3.87796L12.0103 3.50721C11.565 2.83879 10.9908 2.26457 10.3231 1.82072L9.95234 2.46125L9.29975 2.08447L9.66825 1.44694C8.96667 1.09804 8.18899 0.88252 7.36533 0.832031V1.56676H6.61176V0.832031C5.78811 0.88252 5.01043 1.09804 4.30885 1.44694L4.67735 2.08447L4.02476 2.46125L3.65475 1.82072C2.98634 2.26457 2.41211 2.83879 1.96751 3.50721L2.6088 3.87796L2.23201 4.5298L1.59449 4.16206C1.24483 4.86363 1.03007 5.64132 0.978823 6.46497H1.71355V7.21854H0.976562C1.02781 8.0422 1.24483 8.81988 1.59524 9.51995L2.23201 9.15296L2.6088 9.8048L1.96977 10.174C2.06999 10.324 2.17474 10.4694 2.28702 10.6096H11.6908C11.8031 10.4694 11.9079 10.324 12.0073 10.1748L11.3683 9.8048L11.7451 9.15296L12.3826 9.51995Z" />
  //     </g>
  //   </svg>
  // )

  // const historicsvg = (
  //   <svg width="12" height="12" viewBox="0 0 12 12" fill={currentPage === 'historic' ? 'white' : '#0097E0'} xmlns="http://www.w3.org/2000/svg">
  //     <path d="M0.599609 6.0001C0.599609 3.0193 3.01341 0.600098 5.99421 0.600098C8.98041 0.600098 11.3996 3.0193 11.3996 6.0001C11.3996 8.9809 8.98041 11.4001 5.99421 11.4001C3.01341 11.4001 0.599609 8.9809 0.599609 6.0001ZM1.67961 6.0001C1.67961 8.3869 3.61281 10.3201 5.99961 10.3201C8.38641 10.3201 10.3196 8.3869 10.3196 6.0001C10.3196 3.6133 8.38641 1.6801 5.99961 1.6801C3.61281 1.6801 1.67961 3.6133 1.67961 6.0001ZM5.45966 3.30009H6.26966V6.13509L8.69966 7.57689L8.29466 8.24109L5.45966 6.54009V3.30009Z"/>
  //   </svg>
  // )

  const navigate = useNavigate();

  const handleButtonClick = (page) => {
    localStorage.setItem('currentPage', page);
    navigate(`/${page}`); 
  };

  const navigationItems = [
    { page: '', label: 'Schedule', icon: calendarsvg, class: currentPage === '' ? '' : 'hide-class'},    
    { page: 'my-work', label: 'My assigned work', icon: worksvg, class: currentPage === 'my-work' ? '' : 'hide-class'},
  ];

  
  useEffect(() => {
    const fetchProductFamilyNames = async () => {
      if (token) {
        try {
          const response = await axios.get(
            `/getProductFamilyNames`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
  
          const ids = [];
          const names = [];
          for (const key in response.data) {
            if (response.data.hasOwnProperty(key)) {
              const { prodFamilyId, prodFamilyName, plantProdFamilyId } = response.data[key];
              ids.push(prodFamilyId);
              names.push(prodFamilyName);
              plantProdFamilyIds.push(plantProdFamilyId);
            }
          }
          

          setFilterOptions(response);
          setProdFamilyIds(ids);
          setProdFamilyNames(names);          
          setPlantProdFamilyIds(plantProdFamilyIds);
          
        } 
        catch (error) {
        }
      }
    };
  
    fetchProductFamilyNames();
  }, [token]);
  
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const onChange = (event) => {  
    const index = prodFamilyNames.indexOf(event.target.value);
    const correspondingId = prodFamilyIds[index];        
    
    setCurrentLine({
      ProdFamilyId: correspondingId,
      ProdFamilyName: event.target.value,
      PlantProdFamilyId: plantProdFamilyIds[index]
      
    });
    
    // save current line on local storage
    localStorage.setItem('currentLine', JSON.stringify({
      ProdFamilyId: correspondingId,
      ProdFamilyName: event.target.value,
      PlantProdFamilyId: plantProdFamilyIds[index]
    }, null, 2));

  };

  const [lineName, setLineName] = useState(getCurrentLine().ProdFamilyName);
  //Notice changes on current Line service
  useEffect(() => {

  const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setLineName(newCurrentLine.ProdFamilyName);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const filteredProdFamilyNames = prodFamilyNames.filter((name, index) => {
    
    if (employeeRole === 3 || employeeRole === 4) { //If manager or supervisor show all lines
      return true;
    } else {
      const id = prodFamilyIds[index];
      return employeeProdFamilies?.includes(id);    
    }
  });

  useEffect(() => {
    if (employeeProdFamilies?.length < 1 && !isDropdownDisabled ) {
      setCurrentLine({
        ProdFamilyId: "-1",
        ProdFamilyName: "",
        PlantProdFamilyId: "-1"
      });
      localStorage.setItem('currentLine', JSON.stringify({
        ProdFamilyId: "-1",
        ProdFamilyName: "",
        PlantProdFamilyId: "-1"
      }, null, 2));
      if (!isDropdownDisabled) {
        setIsDropdownDisabled(true); // disable dropdown
      }
    }
  }, [prodFamilyNames, employeeProdFamilies]);


  return (
    <header className='bg-white box-shadow' style={{ position: 'fixed', top: 0, zIndex: 100, width: '100%'}}>
      <Navbar className="custom-navbar navbar-expand-md navbar-toggleable-md">
        <img tag={Link} to="/" className="logo-icon" alt="" src="/logo.svg" />
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow" style={{gap: '12px'}}>
            <div className= 'roboto-15-400' style= {{maxWidth: '100px', minWidth: '50px'}}></div>
            {/* Dropdown line selector */}
            {/* {(currentPage === '') && ( */}
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <DropDownList
                  title={'Selected line: ' + lineName}
                  data={ filteredProdFamilyNames}
                  filterable={true}
                  value= {lineName}
                  onChange={onChange}
                  disabled={isDropdownDisabled}
                  style={{
                    minWidth: '200px',
                    border: 'transparent 1px solid',
                    filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.09))',
                    // textAlign: 'center',
                  }}
                />
              </div>      
            {/* )} */}
            {/* Navigation buttons */}
            {navigationItems.map((item, index) => (
              <button
                key={index}
                title={item.label}
                className={`roboto-15-500-2 ${currentPage === item.page ? 'primary-nav-button' : 'secondary-nav-button'}`}
                onClick={() => handleButtonClick(item.page)}
              >
                <div className={item.class}>{item.label}</div>
                {item.icon}
              </button>
            ))}
            <div style= {{maxWidth: '50px'}}></div>
            <button 
              id="myButton"
              className="roboto-15-500 bg-white secondary-button"
              style={{borderRadius: '20px', border: '1px solid white'}} 
              onClick={onClick}
              ref={anchor}
              title={userName}
            >
              <div className='hide-class'>{userName}</div>
              <img
                style={{margin: '8px', width: '32px', height: '32px', borderRadius: '32px'}}
                alt=""
                src={profileImage}
              />
              <svg transform={show ? 'rotate(-180)' : ''} xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325"/>
              </svg>
            </button>
            <Popup 
              anchor={anchor.current}
              anchorAlign={{
                horizontal: "right",
                vertical: "bottom",
              }}
              popupAlign={{
                horizontal: "right",
                vertical: "top",
              }}
              show={show} 
              popupClass={"popup-content"}
              closeOnClick={true}
              >
              <PageLayout></PageLayout>
            </Popup>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}; 