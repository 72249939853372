import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {GRID_COL_INDEX_ATTRIBUTE,} from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import '../GeneralGridStyle.css';

const CustomLockedCell = (props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
  
    const lockedStyles = {
      innerHeight: '100%',
      position: "sticky",
      left: "40px", 
      zIndex: 1, 
      padding: "0px",
      background: 'inherit',
    };

    let icon;

    switch (props.type) {
      case 'edit':
        icon = (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8062 1L14.6062 2.19995L17.8061 5.39983L19.0061 4.19988L15.8062 1ZM13.8062 2.99998L1.40602 15.4L1.0061 19L4.60614 18.5999L17.0063 6.19987L13.8064 2.99999L13.8062 2.99998Z" fill="#0097E0"/>
          </svg>
        );
        break;
      case 'view':
        icon = (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12.0083 16.4219C13.2583 16.4219 14.3208 15.9844 15.1958 15.1094C16.0708 14.2344 16.5083 13.1719 16.5083 11.9219C16.5083 10.6719 16.0708 9.60938 15.1958 8.73438C14.3208 7.85938 13.2583 7.42188 12.0083 7.42188C10.7583 7.42188 9.6958 7.85938 8.8208 8.73438C7.9458 9.60938 7.5083 10.6719 7.5083 11.9219C7.5083 13.1719 7.9458 14.2344 8.8208 15.1094C9.6958 15.9844 10.7583 16.4219 12.0083 16.4219ZM12.0083 14.6219C11.2583 14.6219 10.6208 14.3594 10.0958 13.8344C9.5708 13.3094 9.3083 12.6719 9.3083 11.9219C9.3083 11.1719 9.5708 10.5344 10.0958 10.0094C10.6208 9.48438 11.2583 9.22188 12.0083 9.22188C12.7583 9.22188 13.3958 9.48438 13.9208 10.0094C14.4458 10.5344 14.7083 11.1719 14.7083 11.9219C14.7083 12.6719 14.4458 13.3094 13.9208 13.8344C13.3958 14.3594 12.7583 14.6219 12.0083 14.6219ZM12.0083 19.4219C9.57497 19.4219 7.3583 18.7427 5.3583 17.3844C3.3583 16.026 1.9083 14.2052 1.0083 11.9219C1.9083 9.63854 3.3583 7.81771 5.3583 6.45938C7.3583 5.10104 9.57497 4.42188 12.0083 4.42188C14.4416 4.42188 16.6583 5.10104 18.6583 6.45938C20.6583 7.81771 22.1083 9.63854 23.0083 11.9219C22.1083 14.2052 20.6583 16.026 18.6583 17.3844C16.6583 18.7427 14.4416 19.4219 12.0083 19.4219ZM12.0083 17.4219C13.8916 17.4219 15.6208 16.926 17.1958 15.9344C18.7708 14.9427 19.975 13.6052 20.8083 11.9219C19.975 10.2385 18.7708 8.90104 17.1958 7.90937C15.6208 6.91771 13.8916 6.42188 12.0083 6.42188C10.125 6.42188 8.3958 6.91771 6.8208 7.90937C5.2458 8.90104 4.04163 10.2385 3.2083 11.9219C4.04163 13.6052 5.2458 14.9427 6.8208 15.9344C8.3958 16.926 10.125 17.4219 12.0083 17.4219Z" fill="#0097E0"/>
          </svg>
        );
        break;
      default:
        // icon = 'none'; 
        // break;
        icon = (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12.0083 16.4219C13.2583 16.4219 14.3208 15.9844 15.1958 15.1094C16.0708 14.2344 16.5083 13.1719 16.5083 11.9219C16.5083 10.6719 16.0708 9.60938 15.1958 8.73438C14.3208 7.85938 13.2583 7.42188 12.0083 7.42188C10.7583 7.42188 9.6958 7.85938 8.8208 8.73438C7.9458 9.60938 7.5083 10.6719 7.5083 11.9219C7.5083 13.1719 7.9458 14.2344 8.8208 15.1094C9.6958 15.9844 10.7583 16.4219 12.0083 16.4219ZM12.0083 14.6219C11.2583 14.6219 10.6208 14.3594 10.0958 13.8344C9.5708 13.3094 9.3083 12.6719 9.3083 11.9219C9.3083 11.1719 9.5708 10.5344 10.0958 10.0094C10.6208 9.48438 11.2583 9.22188 12.0083 9.22188C12.7583 9.22188 13.3958 9.48438 13.9208 10.0094C14.4458 10.5344 14.7083 11.1719 14.7083 11.9219C14.7083 12.6719 14.4458 13.3094 13.9208 13.8344C13.3958 14.3594 12.7583 14.6219 12.0083 14.6219ZM12.0083 19.4219C9.57497 19.4219 7.3583 18.7427 5.3583 17.3844C3.3583 16.026 1.9083 14.2052 1.0083 11.9219C1.9083 9.63854 3.3583 7.81771 5.3583 6.45938C7.3583 5.10104 9.57497 4.42188 12.0083 4.42188C14.4416 4.42188 16.6583 5.10104 18.6583 6.45938C20.6583 7.81771 22.1083 9.63854 23.0083 11.9219C22.1083 14.2052 20.6583 16.026 18.6583 17.3844C16.6583 18.7427 14.4416 19.4219 12.0083 19.4219ZM12.0083 17.4219C13.8916 17.4219 15.6208 16.926 17.1958 15.9344C18.7708 14.9427 19.975 13.6052 20.8083 11.9219C19.975 10.2385 18.7708 8.90104 17.1958 7.90937C15.6208 6.91771 13.8916 6.42188 12.0083 6.42188C10.125 6.42188 8.3958 6.91771 6.8208 7.90937C5.2458 8.90104 4.04163 10.2385 3.2083 11.9219C4.04163 13.6052 5.2458 14.9427 6.8208 15.9344C8.3958 16.926 10.125 17.4219 12.0083 17.4219Z" fill="#0097E0"/>
          </svg>
        );
        break;
    }

    const editIcon = [
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8062 1L14.6062 2.19995L17.8061 5.39983L19.0061 4.19988L15.8062 1ZM13.8062 2.99998L1.40602 15.4L1.0061 19L4.60614 18.5999L17.0063 6.19987L13.8064 2.99999L13.8062 2.99998Z" fill="#0097E0"/>
      </svg>
    ];
    const viewIcon = [
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M12.0083 16.4219C13.2583 16.4219 14.3208 15.9844 15.1958 15.1094C16.0708 14.2344 16.5083 13.1719 16.5083 11.9219C16.5083 10.6719 16.0708 9.60938 15.1958 8.73438C14.3208 7.85938 13.2583 7.42188 12.0083 7.42188C10.7583 7.42188 9.6958 7.85938 8.8208 8.73438C7.9458 9.60938 7.5083 10.6719 7.5083 11.9219C7.5083 13.1719 7.9458 14.2344 8.8208 15.1094C9.6958 15.9844 10.7583 16.4219 12.0083 16.4219ZM12.0083 14.6219C11.2583 14.6219 10.6208 14.3594 10.0958 13.8344C9.5708 13.3094 9.3083 12.6719 9.3083 11.9219C9.3083 11.1719 9.5708 10.5344 10.0958 10.0094C10.6208 9.48438 11.2583 9.22188 12.0083 9.22188C12.7583 9.22188 13.3958 9.48438 13.9208 10.0094C14.4458 10.5344 14.7083 11.1719 14.7083 11.9219C14.7083 12.6719 14.4458 13.3094 13.9208 13.8344C13.3958 14.3594 12.7583 14.6219 12.0083 14.6219ZM12.0083 19.4219C9.57497 19.4219 7.3583 18.7427 5.3583 17.3844C3.3583 16.026 1.9083 14.2052 1.0083 11.9219C1.9083 9.63854 3.3583 7.81771 5.3583 6.45938C7.3583 5.10104 9.57497 4.42188 12.0083 4.42188C14.4416 4.42188 16.6583 5.10104 18.6583 6.45938C20.6583 7.81771 22.1083 9.63854 23.0083 11.9219C22.1083 14.2052 20.6583 16.026 18.6583 17.3844C16.6583 18.7427 14.4416 19.4219 12.0083 19.4219ZM12.0083 17.4219C13.8916 17.4219 15.6208 16.926 17.1958 15.9344C18.7708 14.9427 19.975 13.6052 20.8083 11.9219C19.975 10.2385 18.7708 8.90104 17.1958 7.90937C15.6208 6.91771 13.8916 6.42188 12.0083 6.42188C10.125 6.42188 8.3958 6.91771 6.8208 7.90937C5.2458 8.90104 4.04163 10.2385 3.2083 11.9219C4.04163 13.6052 5.2458 14.9427 6.8208 15.9344C8.3958 16.926 10.125 17.4219 12.0083 17.4219Z" fill="#0097E0"/>
      </svg>
    ];

    return (
      <td
        style={{ ...props.style, ...lockedStyles}} 
        className={props.className}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <button 
          fillMode={null} 
          className="k-toolbar-button hidden-child mr-auto ml-auto" 
          style={{ width: '100%', background: 'transparent'}}
          onClick={() => props.enterEdit(props.dataItem)}
        >
          {icon}
        </button>
      </td>
    );
  };
  
  export default CustomLockedCell;