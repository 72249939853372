import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import { subscribeToCurrentLineChanges, getCurrentLine } from '../services/CurrentLineService'; 

const useEmployeeRole = () => {
  const { accounts } = useMsal();
  const { token } = useProfileData(false);
  const [employeeRole, setEmployeeRole] = useState(null);  
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeProdFamilies, setEmployeeProdFamilies] = useState(null);    
  const [error, setError] = useState(null);
  const [currentLine, setCurrentLineState] = useState(getCurrentLine());

  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges((newCurrentLine) => {
      setCurrentLineState(newCurrentLine);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {

    const fetchEmployeeRole = async () => {
      if (token) {
        try {
          const response = await axios.get(`/GetEmployeeID/${accounts[0].username}`, {
            headers: {
              Authorization: token,
            },
          });

          if (response.data) {
            const employeeId = response.data;
            const url = `/GetEmployeeRole/${encodeURIComponent(employeeId)}?plantProdFamId=${encodeURIComponent(currentLine.ProdFamilyId)}`;

            const responseRole = await axios.get(url, {
              headers: {
                Authorization: token,
              },
            });

            const employeeRole1 = responseRole.data.employeeRole;
          
            const titleId = employeeRole1.find(
              role => role.employeeTitleId === 3 || role.employeeTitleId === 4
            )?.employeeTitleId;

            
            setEmployeeRole(titleId || 0); 

            setEmployeeData(responseRole.data.assignation);    
            setEmployeeProdFamilies(responseRole.data.employeeProdFamilyIDs);              
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
      } else {
        console.error("Error fetching data: Token not available");
      }
    };

    fetchEmployeeRole();
  }, [token, accounts, currentLine]);

  return { employeeRole, employeeData, employeeProdFamilies};
};

export default useEmployeeRole;