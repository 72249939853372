import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import './TabStripComponent.css'
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon } from "@progress/kendo-svg-icons";
import axios from '../axiosConfig';
import Toast from './ToastNotification';
import { formatDate } from '../Utilities/utils';
import useProfileData from '../services/ProfileDataService';

const TabComponentChangeODetail = ({item}) => {
  const rowStyle= {margin: '0px 16px',  display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'};

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 170);
  const [infoHeight, setInfoHeight] = useState(gridHeight - 210);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 170;
      setGridHeight(newGridHeight);
      const newInfoHeight = newGridHeight - 210;
      setInfoHeight(newInfoHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  // Main endpoint
  const { token } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);

  useEffect(() => {
    fetchChangeOrdersDetails();
  }, [token]);

  const [changeODetails, setChangeODetails] = useState([]);
  const [changeOText, setChangeOText] = useState([]);
  const [prevValues, setPrevValues] = useState({});
  const [actValues, setActValues] = useState({});
 
  const fetchChangeOrdersDetails = async () => {
    let valuesList = [];
    let mappedValues = {
      ESOI: item.esoi,
      SO: item.so,
      LongChanges: 0,
    };
 
    valuesList = [mappedValues];
 
    try {                  
      if (token) {
        const response = await axios.post(
          `/getChangeOrderDetails/`,
            valuesList,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setChangeODetails(response.data);
        setChangeOText(response.data?.[2]);
        setPrevValues(parseOrderString(response.data?.[0]?.[0]));
        setActValues(parseOrderString(response.data?.[1]?.[0]));
      }
    } catch (error) {
      console.error('Error fetching change order details:', error);
    }
  };

  // Function to convert the string to an object
  function parseOrderString(orderString) {
    return orderString?.split(', ').reduce((obj, item) => {
        const [key, value] = item.split(': ');
        obj[key] = value;
        return obj;
    }, {});
  }

  function highlightDifference(prev, act) {
    if (!prev || !act || prev === act) return act;
  
    // Encuentra el índice donde comienza la diferencia desde el principio
    let startIndex = 0;
    while (startIndex < prev.length && startIndex < act.length && prev[startIndex] === act[startIndex]) {
      startIndex++;
    }
  
    // Encuentra el índice donde termina la diferencia desde el final
    let endIndexPrev = prev.length - 1;
    let endIndexAct = act.length - 1;
    while (endIndexPrev >= startIndex && endIndexAct >= startIndex && prev[endIndexPrev] === act[endIndexAct]) {
      endIndexPrev--;
      endIndexAct--;
    }
  
    if (startIndex <= endIndexAct) {
      return (
        <>
          {act.slice(0, startIndex)}
          <span style={{ textDecoration: 'none',  borderBottom: '2px solid #FF4949'}}>
            {act.slice(startIndex, endIndexAct + 1)}
          </span>
          {act.slice(endIndexAct + 1)}
        </>
      );
    } else {
      return act;
    }
  }
  

  const changesText = changeOText?.slice(1)?.map(change => {
    change = change.replace(/\s12:00:00 AM/g, '');
    return change.trim().replace(/\.$/, '');
  }).join(', ');

  return (
    <div>
      <div style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end', position: 'absolute', top: '75px', left: '30px', gap: '8px'}}> 
        <p className='roboto-19-700' style={{fontSize: '32px'}}>{'SO'}</p>
        <p className='roboto-19-400' style={{fontSize: '32px'}}>{item.so}</p>
        <p className='roboto-19-700' style={{fontSize: '32px'}}>{'ESOI'}</p>
        <p className='roboto-19-400' style={{fontSize: '32px'}}>{item.esoi}</p>
      </div>
      <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
        {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
        <TabStrip
          selected={0}
          style={{ height: gridHeight, border: 'none', display: 'flex',}}
          className='main-c-shadow'
        >
          <TabStripTab title=''>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
              <div className= 'roboto-15-400' style={{...rowStyle, margin: '16px'}}>
                <Button 
                  tag={Link}
                  className='secondary-button roboto-15-500 blue-text' 
                  to="/">
                  <SvgIcon icon={chevronLeftIcon} size={'large'} color='#0097E0'></SvgIcon>
                  Return
                </Button>
              </div>
              <div className='roboto-15-500' style={{...rowStyle, margin: '16px', flexDirection: 'column', alignItems: 'flex-start'}}>
                <p>{'Changes detail '}{formatDate(actValues?.ChangeDate, false, 2)}</p>
                <p className='roboto-15-400' style={{display: changesText !== '' ? 'flex' : 'none'}}>{changesText}</p>
                <p className='roboto-15-400' style={{display: changesText !== '' ? 'none' : 'flex'}}>{'A field other than SHIP DATE, SPECIAL TEXT, CODE STRING, GO STATUS, or BOM REVISION has changed'}</p>
              </div>
              {changesText !== '' && (
                <div>
                  <div className='my-work-card roboto-15-400' style={{...rowStyle, borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}>
                    <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{width: '10%'}}>VALUE</div>
                        <div style={{width: '40%'}}>PREVIOUS VALUE</div>
                        <div style={{width: '40%'}}>NEW VALUE</div>
                      </div>
                    </div>
                  </div>
                  <div className='my-work-card roboto-15-400' style={{...rowStyle, borderRadius: '0px', borderBottom: 'none'}}>
                    <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{width: '10%'}}><b>SHIP DATE</b></div>
                        <div style={{width: '40%'}}>{formatDate(prevValues?.ShipDate, false, 2)}</div>
                        <div style={{width: '40%'}}>{highlightDifference(formatDate(prevValues?.ShipDate, false, 2), formatDate(actValues?.ShipDate, false, 2))}</div>
                      </div>
                    </div>
                  </div>
                  <div className='my-work-card roboto-15-400' style={{...rowStyle, borderRadius: '0px', borderBottom: 'none'}}>
                    <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 'auto', wordWrap: 'break-word', overflow: 'hidden', boxSizing: 'border-box'}}>
                      <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{width: '10%'}}><b>SPECIAL TEXT</b></div>
                        <div style={{width: '40%'}}>{prevValues?.SpecialText}</div>
                        <div style={{width: '40%'}}>{highlightDifference(prevValues?.SpecialText, actValues?.SpecialText)}</div>
                      </div>
                    </div>
                  </div>
                  <div className='my-work-card roboto-15-400' style={{...rowStyle, borderRadius: '0px', borderBottom: 'none'}}>
                <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 'auto', wordWrap: 'break-word', overflow: 'hidden', boxSizing: 'border-box'}}>
                  <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{width: '10%'}}><b>CODE STRING</b></div>
                    <div style={{width: '40%'}}>{prevValues?.CodeString}</div>
                    <div style={{width: '40%'}}>{highlightDifference(prevValues?.CodeString, actValues?.CodeString)}</div>
                  </div>
                </div>
              </div>
              <div className='my-work-card roboto-15-400' style={{...rowStyle, borderRadius: '0px', borderBottom: 'none'}}>
                <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{width: '10%'}}><b>GO STATUS</b></div>
                    <div style={{width: '40%'}}>{prevValues?.GOStatus}</div>
                    <div style={{width: '40%'}}>{highlightDifference(prevValues?.GOStatus, actValues?.GOStatus)}</div>
                  </div>
                </div>
              </div>
              <div className='my-work-card roboto-15-400' style={{...rowStyle, borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}>
                <div className='roboto-15-400' style={{ display: 'flex', padding: '8px 16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ gap: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{width: '10%'}}><b>BOM REVISION</b></div>
                    <div style={{width: '40%'}}>{prevValues?.BOMRevision}</div>
                    <div style={{width: '40%'}}>{highlightDifference(prevValues?.BOMRevision, actValues?.BOMRevision)}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
    </div>
  );
};

export default TabComponentChangeODetail;

