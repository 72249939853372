import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CodeCompareView from '../components/CodeCompareView';

const CodeCompare = () => {  
  const { orderDetailId1, esoi1, orderDetailId2, esoi2 } = useParams();
  const location = useLocation();
  const orderData = location.state?.orderData;

  return (
    <div style={{ marginTop: '10px' }}>
      <CodeCompareView 
        orderDetailId1={orderDetailId1}
        esoi1={esoi1}
        orderDetailId2={orderDetailId2}
        esoi2={esoi2}
        item={orderData}
      />
    </div>
  );
};

export default CodeCompare;