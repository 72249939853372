import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";

const styles = {
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: '8px'
    },
    text: {
      color: 'black',
      fontSize: '16px',
    },
  };

const Modal = ({ title, type, message, visible, onClose, onAccept, image }) => {
    const handleAccept = () => {
        // Lógica específica para onAccept dependiendo del tipo de modal
        if (type === 'SUCCESS') {
          // Acciones para modal de éxito
        } else if (type === 'ERROR') {
          // Acciones para modal de error
        } else if (type === 'WARNING') {
          // Acciones para modal de advertencia
        } else if (type === 'INFORMATION') {
          // Acciones para modal de información
        }
    
        onClose();
      };
    
    let icon;
    let buttons;
    let profileImage;

    const closeButton = <Button className= 'roboto-15-500 primary-button' onClick={onClose}>Close</Button>;
    const acceptButton = <Button className= 'roboto-15-500 primary-button' onClick={onAccept}>Accept</Button>;
    const deleteButton = <Button className= 'roboto-15-500 primary-button' onClick={onAccept}>Delete</Button>;
    const cancelButton = <Button className= 'roboto-15-500 secondary-button blue-text' onClick={onClose}>Cancel</Button>;
    const xButton = <Button
        onClick={onClose} 
        style={{
            justifyContent: 'flex-end',
            padding: '0px',
            paddingBottom: '1px',
            display: 'block',
            width: '32px',
            height: '32px',
            borderRadius: '180px',
            backgroundColor: '#F2F2F2',
            border: 'none',
        }}> <SvgIcon icon={xIcon} size={'large'} color='#8D8D8D'></SvgIcon>
        </Button>
    switch (type) {
        case 'SUCCESS':
            icon = (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <mask id="mask0_89_3399" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
              <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_89_3399)">
              <path d="M11.3856 17.4462L18.4356 10.3962L17.0356 8.99619L11.3856 14.6462L8.53564 11.7962L7.13564 13.1962L11.3856 17.4462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z" fill="#13CE66"/>
            </g>
          </svg> );
            buttons = (
                <div style={styles.buttonsContainer}>
                    {closeButton}
                </div>
                );
            break;
        case 'ERROR':
            icon = (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <mask id="mask0_89_2934" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
              <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_89_2934)">
              <path d="M12.7856 17.8462C13.069 17.8462 13.3065 17.7504 13.4981 17.5587C13.6898 17.367 13.7856 17.1295 13.7856 16.8462C13.7856 16.5629 13.6898 16.3254 13.4981 16.1337C13.3065 15.942 13.069 15.8462 12.7856 15.8462C12.5023 15.8462 12.2648 15.942 12.0731 16.1337C11.8815 16.3254 11.7856 16.5629 11.7856 16.8462C11.7856 17.1295 11.8815 17.367 12.0731 17.5587C12.2648 17.7504 12.5023 17.8462 12.7856 17.8462ZM11.7856 13.8462H13.7856V7.84619H11.7856V13.8462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z" fill="#FF4949"/>
            </g>
            </svg> );
            buttons = (
                <div style={styles.buttonsContainer}>
                    {closeButton}
                </div>
                );
            break;
        case 'DELETE':
          icon = (
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <mask id="mask0_89_2934" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
            <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_89_2934)">
            <path d="M12.7856 17.8462C13.069 17.8462 13.3065 17.7504 13.4981 17.5587C13.6898 17.367 13.7856 17.1295 13.7856 16.8462C13.7856 16.5629 13.6898 16.3254 13.4981 16.1337C13.3065 15.942 13.069 15.8462 12.7856 15.8462C12.5023 15.8462 12.2648 15.942 12.0731 16.1337C11.8815 16.3254 11.7856 16.5629 11.7856 16.8462C11.7856 17.1295 11.8815 17.367 12.0731 17.5587C12.2648 17.7504 12.5023 17.8462 12.7856 17.8462ZM11.7856 13.8462H13.7856V7.84619H11.7856V13.8462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z" fill="#FF4949"/>
          </g>
          </svg> );
          buttons = (
            <div style={styles.buttonsContainer}>
                {cancelButton}
                {deleteButton}
            </div>
              );
          break;
        case 'WARNING':
            icon = (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <mask id="mask0_89_1946" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                <rect x="0.785645" y="0.103516" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_89_1946)">
                <path d="M1.78564 21.1035L12.7856 2.10352L23.7856 21.1035H1.78564ZM12.7856 18.1035C13.069 18.1035 13.3065 18.0077 13.4981 17.816C13.6898 17.6243 13.7856 17.3868 13.7856 17.1035C13.7856 16.8202 13.6898 16.5827 13.4981 16.391C13.3065 16.1993 13.069 16.1035 12.7856 16.1035C12.5023 16.1035 12.2648 16.1993 12.0731 16.391C11.8815 16.5827 11.7856 16.8202 11.7856 17.1035C11.7856 17.3868 11.8815 17.6243 12.0731 17.816C12.2648 18.0077 12.5023 18.1035 12.7856 18.1035ZM11.7856 15.1035H13.7856V10.1035H11.7856V15.1035Z" fill="#FFCC3D"/>
            </g>
            </svg> );
            buttons = (
                <div style={styles.buttonsContainer}>
                    {acceptButton}
                </div>
                );
            break;
        case 'INFORMATION':
            icon = (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <mask id="mask0_89_1481" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                <rect x="0.785645" y="0.103516" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_89_1481)">
                <path d="M11.7856 17.1035H13.7856V11.1035H11.7856V17.1035ZM12.7856 9.10352C13.069 9.10352 13.3065 9.00768 13.4981 8.81602C13.6898 8.62435 13.7856 8.38685 13.7856 8.10352C13.7856 7.82018 13.6898 7.58268 13.4981 7.39102C13.3065 7.19935 13.069 7.10352 12.7856 7.10352C12.5023 7.10352 12.2648 7.19935 12.0731 7.39102C11.8815 7.58268 11.7856 7.82018 11.7856 8.10352C11.7856 8.38685 11.8815 8.62435 12.0731 8.81602C12.2648 9.00768 12.5023 9.10352 12.7856 9.10352ZM12.7856 22.1035C11.4023 22.1035 10.1023 21.841 8.88564 21.316C7.66898 20.791 6.61064 20.0785 5.71064 19.1785C4.81064 18.2785 4.09814 17.2202 3.57314 16.0035C3.04814 14.7868 2.78564 13.4868 2.78564 12.1035C2.78564 10.7202 3.04814 9.42018 3.57314 8.20352C4.09814 6.98685 4.81064 5.92852 5.71064 5.02852C6.61064 4.12852 7.66898 3.41602 8.88564 2.89102C10.1023 2.36602 11.4023 2.10352 12.7856 2.10352C14.169 2.10352 15.469 2.36602 16.6856 2.89102C17.9023 3.41602 18.9606 4.12852 19.8606 5.02852C20.7606 5.92852 21.4731 6.98685 21.9981 8.20352C22.5231 9.42018 22.7856 10.7202 22.7856 12.1035C22.7856 13.4868 22.5231 14.7868 21.9981 16.0035C21.4731 17.2202 20.7606 18.2785 19.8606 19.1785C18.9606 20.0785 17.9023 20.791 16.6856 21.316C15.469 21.841 14.169 22.1035 12.7856 22.1035Z" fill="#0097E0"/>
            </g>
            </svg> );
            buttons = (
                <div style={styles.buttonsContainer}>
                    {cancelButton}
                    {acceptButton}
                </div>
                );
            break;
          case 'TAKENORDER':
              profileImage = (
              <img
                style={{margin: '8px', width: '32px', height: '32px', borderRadius: '32px'}}
                alt=""
                src={image}
              /> );
              buttons = (
                  <div style={styles.buttonsContainer}>
                      {closeButton}
                  </div>
                  );
              break;
        default:
            icon = 'none'; 
            buttons = 'none';
            break;
    }

  return (
    visible && (
    <Dialog 
        visible={visible}
        height= {'224px'}
        width= {'512px'}
        >
      <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'top', width: '100%'}}>
            <div style={{gap: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {icon}
                <div className='roboto-19-700'>{title}</div>
            </div>
            {/* {xButton} */}
        </div>
        <div className='roboto-15-500' style={{textAlign: 'left'}}>{profileImage}{message}</div>
        {buttons}
      </div>
    </Dialog>
    )
  );
};

export default Modal;
