import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import './TabStripComponent.css'
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon } from "@progress/kendo-svg-icons";
import axios from '../axiosConfig';
import Toast from './ToastNotification';
import useProfileData from '../services/ProfileDataService';
import { Switch } from '@progress/kendo-react-inputs';
import './codeCompareView.css';
import ComparisonRow from './ComparisonRow';
import ComparisonRowWithInnerRows from './ComparisonRowWithInnerRows';

const CodeCompareView = ({ item}) => { 

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 170);
  const [infoHeight, setInfoHeight] = useState(gridHeight - 210);

  const [data, setData] = useState([]);


  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 170;
      setGridHeight(newGridHeight);
      const newInfoHeight = newGridHeight - 210;
      setInfoHeight(newInfoHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  // Main endpoint
  const { token } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
    
  const [splitCodes, setSplitCodes] = useState([]);  
  const [isChecked, setIsChecked] = useState(true); 

  useEffect(() => {
    fetchCodeSplit();        
  }, [token]);

  const fetchCodeSplit = async () => {
    if (token && item.length > 0) {
        try {
            const responses = [];
            const differences = [];

            const response = await axios.get(
              `/getCodeStringComparison?esoiNumber=${encodeURIComponent(item[0].esoi)}&PCL=${encodeURIComponent(item[0].pcl)}&codeString1=${encodeURIComponent(item[0].code)}&codeString2=${encodeURIComponent(item[1].code)}`,
              {
                  headers: {
                      Authorization: token,
                  },
              }
            );          

            responses.push(response.data.codeStringSplitWhole1);
            responses.push(response.data.codeStringSplitWhole2);

            differences.push(formatDifferences(response.data.codeDifferences1));
            differences.push(formatDifferences(response.data.codeDifferences2));

            setData(differences);
            setSplitCodes(responses);

        } catch (error) {
            handleShowMessage(error.response.data, "error");
        }
    }
};

  const handleShowMessage = async (text, state) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });  
  };
 
  const handleChange = (event) => {    
    setIsChecked(event.value);     
  };

  function highlightCodeItemsDifference(act, differencesInCode) {    
    const highlightStyle = { textDecoration: 'none', borderBottom: '2px solid #0097E0', color: '#0097E0' };
  
    const actItems = act?.split(' ');
  
    return (
      <>
        {actItems?.map((item, index) => {
          const indexString = index.toString(); 

          const match = differencesInCode && Array.isArray(differencesInCode) && isChecked
            ? differencesInCode.find(diff => diff.codeItem && diff.codeItem.replace('.', '') === (indexString).toString())
            : null;
          if (match) {
            return (
              <span key={index} style={highlightStyle}>
                {item}{" "}
              </span>
            );
          } else {
            return <span key={index}>{item}{" "}</span>;
          }
        })}
      </>
    );
  }
        
  function formatDifferences(differencesArray) {
    return differencesArray.map(diff => {
      const [codeItem, description, value] = diff.split(',');
  
      return {
        codeItem: codeItem.trim(),
        description: description.trim(),
        value: value.trim()
      };
    });
  }    
    
  return (
    <div>
      <div className="d-flex flex-row align-items-end position-absolute header-container">  
        <p className='roboto-29-700 mt-4 text-left' >{'COMPARE CODES'}</p>
      </div>
      <div className='px-4 mx-2' >
        {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
        <TabStrip
          selected={0}
          style={{ minHeight: gridHeight}}
          className='main-c-shadow mt-4 tabstrip-container'>
          <TabStripTab title=''>
            <div className="d-flex flex-column justify-content-evenly">
              <div className= 'roboto-15-400 mx-3 mt-3 mb-2 row-style'>
                <Button 
                  tag={Link}                  
                  className=' roboto-15-500 blue-text return-button fs-15' 
                  to="/">
                  <SvgIcon icon={chevronLeftIcon} size={'large'} color='#0097E0'></SvgIcon>
                  <p className='return-text'>Return</p>
                </Button>
              </div>
              <div className="roboto-16-700 mt-1 mb-3 mx-3 d-flex justify-content-between align-items-center header-row">
                <div></div>
                <div className="d-flex align-items-center switch-container">
                  <Switch
                    checked={isChecked}
                    onChange={handleChange}
                    
                  />
                  <span className='roboto-13-400'>Highlight differences</span>
                </div>
              </div>
              {item && (
                    <div>
                    {/* headers */}
                    <div className="my-work-card roboto-15-400 custom-border row-style" >
                      <div className="roboto-15-400 d-flex justify-content-between align-items-center card-row">
                        <div className="d-flex flex-row align-items-center justify-content-between card-content">
                          <div className='w-10'>VALUE</div>
                          <div className='w-40'>VALUES</div>
                          <div className='w-40'>VALUES</div>
                        </div>
                      </div>
                    </div>
              
                    {/*ESOI */}
                    <ComparisonRow
                      label="ESOI"
                      value1={<b>{item[0]?.esoi} </b>}
                      value2={<b>{item[1]?.esoi} </b>}
                      isBold={true}
                      isLastRow={false}
                    />
              
                    {/* CODE */}
                    <ComparisonRow
                      label="CODE"
                      value1={highlightCodeItemsDifference(splitCodes[0], data[0])}
                      value2={highlightCodeItemsDifference(splitCodes[1], data[1])}
                      isBold={true}
                      isLastRow={false}
                    />
                  

                    {/* Differences in Code*/}
                    <ComparisonRowWithInnerRows
                      label="Differences in Code"
                      differences={data}
                      isBold={true}
                      isLastRow={true}
                    />
                  </div>
          )}
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
    </div>
  );
};

export default CodeCompareView;

