import React, { Component } from 'react';

export class Dashboard extends Component {
  render() {
    return (
    <div>
      <p>This is the DASHBOARD Page content.</p>
    </div>
  );
}}
