import React from 'react';
import { useLocation } from 'react-router-dom';
import TabComponentCodeSplit from '../components/TabComponentCodeSplit';

const CodeSplit = () => {
  const location = useLocation();
  const orderData = location.state?.orderData;

  return (
    <div style={{ marginTop: '10px' }}>
      <TabComponentCodeSplit 
        item= {orderData}>
        </TabComponentCodeSplit>
    </div>
  );
};

export default CodeSplit;

