import React from 'react';

const ArrowIcon = ({ show }) => (
  <div>
    <svg
      transform={show ? 'rotate(-180)' : ''}
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="#1C2325"
    >
      <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325" />
    </svg>
  </div>
);

export default ArrowIcon;