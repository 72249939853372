import React from 'react';
import './CustomToast.css'; 

const CustomNotification = ({ type, onClose, children }) => {
  return (
    <div className={`custom-notification ${type}`}>
      <span className="material-symbols-outlined icon">error</span>
      <span className="notification-message">{children}</span>
      <button className="close-button" onClick={onClose}>
        <span className="material-symbols-outlined">close</span>
      </button>
    </div>
  );
};

const CustomToast = ({ showDiv, message, onClose }) => {
  return (
    <div className="custom-toast">
      {showDiv && (
        <CustomNotification type="error" onClose={onClose}>
          {message}
        </CustomNotification>
      )}
    </div>
  );
};

export default CustomToast;