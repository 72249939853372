import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TabComponentChangeODetail from '../components/TabComponentChangeODetail';

const ChangeOrderDetail = () => {
  const { orderDetailId, esoi } = useParams();
  const location = useLocation();
  const orderData = location.state?.orderData;

  return (
    <div style={{ marginTop: '10px' }}>
      <TabComponentChangeODetail 
        item= {orderData}>
        </TabComponentChangeODetail>
    </div>
  );
};

export default ChangeOrderDetail;

