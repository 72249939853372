import React from 'react';
import TabComponentDraftHours from '../components/TabComponentDraftHours';

const Drafthours = () => {
  return (
    <div style={{marginTop: '10px'}}>
      <TabComponentDraftHours></TabComponentDraftHours>
    </div>
  );
};

export default Drafthours;