export const truncateEmail = (mail, max) => {
    const firstIndex = mail.indexOf('@');
    const truncatedEmail = firstIndex !== -1
      ? mail.substring(0, Math.min(firstIndex, max))
      : mail.substring(0, max);
    return truncatedEmail;
  };

export const formatDate = (dateString, includeHour, option = 0) => {
  if (!dateString) return '';    

  let date = new Date(dateString);

  const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      timeZone: option !== 0 ? 'UTC' : undefined
  };

  const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: option !== 0 ? 'UTC' : undefined
  };
  
  if(option === 0) {
      const localOffset = new Date().getTimezoneOffset() * 60000;
      const adjustedTime = date.getTime() - localOffset;
      date = new Date(adjustedTime);
  }

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return includeHour ? `${formattedDate}, ${formattedTime}` : `${formattedDate}`;
};
