import React from 'react';
import './codeCompareView.css';

const ComparisonRowWithInnerRows = ({ label, differences, isBold, isLastRow }) => {
  return (
    <div className={`my-work-card roboto-15-400 row-style ${isLastRow ? 'lastRow' : 'no-border'}`}>
      <div className="roboto-15-400 d-flex justify-content-between align-items-center card-row word-wrap-container">
        <div className="d-flex flex-row align-items-center justify-content-between card-content">
          <div className='w-10'>{isBold ? <b>{label}</b> : label}</div>          
          {differences.map((diffGroup, groupIndex) => (
            <div key={groupIndex} className="w-40">
              {diffGroup.map((diff, index) => (
                <div key={index} className="difference-row-item mb-2">
                  <div>{diff.codeItem} {diff.description}</div>
                  <div>{diff.value}</div>
                </div>
              ))}
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default ComparisonRowWithInnerRows;