import React, { useState, useEffect } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

const CustomNotification = ({id, type, closable, onClose, children, customIcon }) => {
  return (
    <Notification
      key={id}
      type={{ style: type, icon: false }}
      closable={closable}
      onClose={onClose}
    >
      {customIcon && <span style={{ marginRight: '8px' }}>{customIcon}</span>}
      {children}
    </Notification>
  );
};

const Toast = ({ showToast, message, type }) => {
  const [isVisible, setIsVisible] = useState(false);  
  useEffect(() => {
    if (showToast) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 6000); 
    }
  }, [showToast]);

  const getCustomIcon = () => {
    switch (type) {
      case 'success':
        return <span className="material-symbols-outlined icon">check_circle</span>; 
      case 'warning':
        return <span className="material-symbols-outlined icon">warning</span>; 
      case 'error':
        return  <span className="material-symbols-outlined icon">error</span>;  
      case 'info':
        return <span className="material-symbols-outlined icon">info</span>
      default:
        return null;
    }
  };

  return (
    <NotificationGroup
      style={{
        right: 0,
        top: 68,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }}
    >
     <Fade onExit={() => setIsVisible(false)}>
  {isVisible && (
    <CustomNotification
      type={type}
      closable={true}
      onClose={() => setIsVisible(false)}
      customIcon={getCustomIcon()}
    >
      <span>{message}</span>
    </CustomNotification>
  )}
</Fade>
    </NotificationGroup>
  );
};

export default Toast;
