import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import '@progress/kendo-theme-default/dist/all.css';
import './GeneralGridStyle.css';
import CustomLockedCell from './customizedColumns/CustomLockedCell'
import useProfileData from '../services/ProfileDataService';
import CustomFilter from '../components/customizedFilters/CustomFilter';
import Loader from './Loader';
import Toast from './ToastNotification';
import { useMsal } from "@azure/msal-react";
import Modal from './Modal';
import referenceOrdersMapping from '../Utilities/referenceOrdersColumnFieldMapping'
import { ExcelExport } from "@progress/kendo-react-excel-export";
import LogOutService from '../services/LogOutService';
import SpecialTextCell from './customizedColumns/SpecialTextCell';
import { Window } from "@progress/kendo-react-dialogs";

const ReferenceOrdersGrid = ({ columns = [], dateRange,
  filter, setFilter,
  prevFilter, setPrevFilter,
  currentFilter, setCurrentFilter, onRemoveAll,
  setRemoveFilter, dropdownFilters, setDropdownFilters, removeFilter, disabled,
  exportExcel, setExportExcel, modelTypes, setColumns, setDisableColumnsSelector,
  formatNumber, selectedItems, setInitialFilterTitle
}) => {
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [sort, setSort] = useState([{ field: "promiseDate", dir: "desc" }]);
  const [page, setPage] = useState({ skip: 0, take: pageSize });

  const { token } = useProfileData(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);  
  
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const { accounts, instance } = useMsal();  
  const viewAlias = 4;


  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const fetchData = async () => {    
    try {      
      setLoading(true);

      const filterValues = modelTypes.filter(item => item.selected).map(item => item.value);
      
      let modifiedFilter = filter; 

      if (filter) {
          modifiedFilter = {
              ...filter,
              filters: filter.filters ? filter.filters.map(filterItem => {
                  const filterOption = filterOptions.find(option => option.columnName === filterItem.field);
                  if (filterOption) {
                      return {
                          ...filterItem,
                          CodeStart: filterOption.codeStart,
                          CodeLength: filterOption.codeLength
                      };
                  } else {
                    return {
                      ...filterItem,
                      field:  capitalizeFirstLetter(filterItem.field),
                      CodeStart: -1,                      
                  };
                  }
              }) : []
          };
      }
            

      if (token) {
        const response = await axios.post(
          `/findReferenceOrders?&formatNumber=${encodeURIComponent(formatNumber)}`,
          {

            sort: sort?.[0]?.dir || 'des',
            skip: page.skip,
            take: page.take,
            filter: JSON.stringify(modifiedFilter, null, 2),
            column: sort?.[0]?.field || 'promiseDate',
            modelTypes: filterValues
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );        

        const modifiedItems = response.data.items.map((item, index) => {
          const combinedData = {};
          item.codeSplitHeaders.forEach((header, index) => {
              combinedData[header] = item.codeSplit[index];
          });
          return {
              ...item,
              ...combinedData,
              rowIndex: index + 1 + page.skip
          };
      });

      setUsers(modifiedItems);
      setTotalRows(response.data.totalRows);
      setLoading(false);      
    }
    } catch (error) {

      if (error.response) {
        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);         

      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }
      setLoading(false);
    }
  };



  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast + 1);

    if(modelTypes.some(option => option.selected))
      fetchData();    
  }, [token, page.skip, page.take, sort, dateRange, filter]);

  useEffect(() => {
    setPage({ skip: 0, take: pageSize });
    setCurrentPage(1);
  }, [dateRange, sort, filter]);

  useEffect(() => {
    if(modelTypes.some(option => option.selected))
     fetchData();

  }, [modelTypes]);

  const [filtro, setFiltro] = useState(null)

  // **************Static filters approach**************
  const [filterOptions, setFilterOptions] = useState([])

  const commonFieldValues = [
    'Ship Date',
    'Sequence number',
    'Order Number',
    'Status',
    'Qty',
    'ESOI Number',
    'Description',
    'Special Text',
    'Code',    
    '01 UNIT TYPE'
  ];

  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {          
    const fetchReferenceOptions = async () => {
      setLoading(true);    
      if (token) {
        try {
          const firstResponse = await axios.get(
            `/getCodeNumbers?&formatNumber=${encodeURIComponent(formatNumber)}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );                                
          
          setInitialFilterTitle(firstResponse.data[0].ciDescription);          
          const newColumns = [];
  
          // Define columns values
          for (let i = 0; i < firstResponse.data.length; i++) {
            const field = firstResponse.data[i].ciDescription;
  
            const columnDataOptions = {
              field: field, 
              title: (i + 1).toString().padStart(2, '0') + " " + field, // start with 0
              filterType: 'dropdown',
              visible: true,
              minWidth: 80                                                                  
            };
  
            newColumns.push(columnDataOptions);
          }
  
          const codeNumbersResponse = await axios.get(
            `/selectionsByCodeNum?&formatNumber=${encodeURIComponent(formatNumber)}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          
          //Create new array with grouped options by fiCodeItem
          const groupedData = Array.isArray(codeNumbersResponse.data) 
            ? codeNumbersResponse.data.reduce((acc, current) => {
                const { fiCodeItem, cvOption, ...rest } = current;
                const existingItem = acc.find(item => item.fiCodeItem === fiCodeItem);
        
                if (existingItem) {
                  if (Array.isArray(cvOption)) {
                    cvOption.forEach(option => {
                      if (!existingItem.cvOption.includes(option)) {
                        existingItem.cvOption.push(option);
                      }
                    });
                  } else if (!existingItem.cvOption.includes(cvOption)) {
                    existingItem.cvOption.push(cvOption);
                  }
                } else {
                  acc.push({
                    fiCodeItem,
                    cvOption: Array.isArray(cvOption) ? cvOption : [cvOption],
                    ...rest
                  });
                }
        
                return acc;
              }, [])
            : [];
           
          //final object used to display columns with their options in the dropdown
          const allResponses = groupedData.map((item, index) => {
            return {
              columnName: newColumns[index]?.field || item.fiCodeItem, 
              codeStart: item.fiStartPosition,
              codeLength: item.fiLength,
              uniqueValues: item.cvOption
            };
          });
  
          setFilterOptions(allResponses);
          //Map dinamically the codeItemsColumns
          const updatedColumns = [...columns, ...newColumns];  
          setColumns(updatedColumns);                                
          setDisableColumnsSelector(false);              

          //Apply filters sent from code split view
          setFilter((prevFilter) => {
            const newFilter = prevFilter
              ? { ...prevFilter }
              : { logic: "or", filters: [] };
          
            const existingFields = new Set(newFilter.filters.map(f => f.field + f.value));
            
            //create filters object
            selectedItems.forEach((item) => {
              if (item.selected && !existingFields.has(item.Description + item.Value)) {
                newFilter.filters.push({
                  field: item.Description,
                  operator: "contains",
                  value: item.Value,
                });
              }
            });
                                  
            return newFilter; 
          });
          
          const currentFilterField = selectedItems.length > 0 ? selectedItems[0].Description : null;
          
          if (currentFilterField) {
            const selectedOptionsForField = selectedItems
              .filter(item => item.Description === currentFilterField)
              .map(item => item.Value);
          
            setFiltro(currentFilterField); 
            setCurrentFilter(selectedOptionsForField);  
            setRemoveFilter(false);
          
            setFilter((filter) => {
              const allFields = filter?.filters?.map((filter) => filter.field) || [];
              allFields.unshift(currentFilterField);
              const uniqueFields = [...new Set(allFields)];
          
              setPrevFilter(uniqueFields); 
              setDropdownFilters(filter?.filters); 
          
              return filter;  
            });
          }

          setLoading(false);
                                   
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    };
  
    fetchReferenceOptions();       
  }, [token]);
  
  const filterConfig = {
    text: "text",
    number: "numeric",
    boolean: "boolean",
    date: "date",
    dropdown: {
      filterCell: (props) => {
        const { field } = props;
        return (
          <CustomFilter
            {...props}
            data={users}
            field={field}
            currentFilterField={field}
            onSelectionChange={handleCustomFilterChange}
            onRemoveAll={onRemoveAll}
            selectedOptions2={currentFilter}
            selectedFilter={filtro}
            removeFilter={removeFilter}
            prevFilter={prevFilter}
            filter={dropdownFilters}
            viewOption={viewAlias}//referenceOrders
            endPointData={filterOptions}
          />
        );
      }
    }
  };

  const handleCustomFilterChange = (selectedOptions, currentFilterField) => {    
        
    setFilter((prevFilter) => {
      if (!prevFilter) {
        // No filter exist
        return {
          logic: "or",
          filters: [
            ...selectedOptions.map((option) => ({
              field: currentFilterField,
              operator: "contains",
              value: option,
            })),
          ],
        };
      } else {
        // if already exist a filter
        const updatedFilters = [
          ...prevFilter.filters.filter((filter) => filter.field !== currentFilterField),
          ...selectedOptions.map((option) => ({
            field: currentFilterField,
            operator: "contains",
            value: option,
          })),
        ];

        return {
          ...prevFilter,
          filters: updatedFilters,
        };
      }
    });
    setFiltro(currentFilterField);
    setCurrentFilter(selectedOptions);
    setRemoveFilter(false);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    allFields.unshift(currentFilterField);
    const allFields2 = [...new Set(allFields)];
    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters);
  };


  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const newPage = {
      skip: event.page.skip,
      take: targetEvent.value === 'All' ? totalRows : event.page.take,
    };

    setPage(newPage);
    setCurrentPage(newPage.skip / pageSize + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.filter);
  };

  const filteredUsers = filterBy(users, filter);
  const sortedUsers = orderBy(filteredUsers, sort);

  

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns], [sortedUsers]);

  const [selectedColumnFields, setSelectedColumnFields] = useState(columns.map(column => column.field));

  useEffect(() => {
    setSelectedColumnFields(columns.map(column => column.field));    
  }, [columns]);

  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;

    const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
    if (token) {
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      const imageSrc = imageUrl;

      if (imageSrc === null) {
        setProfileImgAssigned('/DefaultProfile.png');
      }
      else {
        setProfileImgAssigned(imageSrc);
      }

      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };

  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState();
  const [data, setData] = useState(sortedUsers);

  const enterEditSidePanel = async (item) => {

    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenSidePanel(true);
    setEditItem(updatedItem);

  };

  const enterEdit = async (item) => {

    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);

  };
  
  const handleCancelEdit = () => {
    setOpenForm(false);
    setOpenSidePanel(false);
  };

  const MyCustomLockedCell = (props) => (
    <CustomLockedCell {...props} enterEdit={enterEdit}  type={'edit'}/>
  );

  const handleSortChange = (e) => {
    setSort(e.sort || []);
  };

  const handleModalError = async (image, userName) => {
    downloadFile(image);
    setName(userName);
    setModalVisible(true);

    await fetchData();
  };

  const _export = React.useRef(null);
  const _grid = React.useRef();

  const _formattedDate = (() => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  })();

  const excelExport = () => {
    if (_export.current !== null && exportExcel === true) {

      const today = new Date();
      _export.current.save(users);
    }
  };

  useEffect(() => {
    excelExport();
    setExportExcel(false);
  }, [exportExcel === true]);


  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 270);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 270;
      setGridHeight(newGridHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <Loader></Loader>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
        image= {profileImgAssigned}
      />

      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}

      <div style={{ height: gridHeight, marginBottom: '15px', position: 'relative' }}>
        <ExcelExport ref={_export} fileName={`newOrders_${_formattedDate}.xlsx`}>
          <Grid
            className='roboto-15-400'
            data={(users || [])}
            skip={page.skip}
            take={page.take}
            total={totalRows}
            pageable={{
              buttonCount: 4,
              type: "input",
              pageSizes: [ 50, 150, 200].filter(num => num <= totalRows),
              pageSize: pageSize,
            }}
            onPageChange={pageChange}
            sortable={true}
            filterable={true}
            filter={filter}
            onFilterChange={handleFilterChange}
            resizable
            reorderable
            sort={sort}
            onSortChange={handleSortChange}
            style={{
              borderRadius: '8px',
              display: 'flex',
              margin: '15px',
              height: 'inherit'
            }}
            ref={_grid}
          >
            <GridColumn
              className='roboto-15-400'
              reorderable={false}
              sortable={false}
              filterable={false}
              editable={false}
              field="rowIndex"
              title="#"
              width="40px"
            />
            {selectedColumns
              .filter((column) => column.visible)
              .map((column) => {
                if (column.title === "Special Text") {
                  return (
                    <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filterable={true} // change to true after handling this filter on the back end
                    // cell={column.cell}
                    cell={(props) => (
                      <SpecialTextCell
                        {...props} enterEdit={enterEditSidePanel}
                      />
                    )}
                  />
                  );

                } 
                 else if (commonFieldValues.includes(column.title)) {
                  return (
                    <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filterable={false}
                    cell={column.cell}
                  />
                  );

                } 
                else {
                  
                  return (
                    <GridColumn
                      className='roboto-15-400'
                      sortable={false}
                      key={column.field}
                      field={column.field}
                      title={column.title}
                      headerCell={column.header}
                      width={column.minWidth}
                      filter={filterConfig[column.filterType]}
                      format="{0:d}"
                      filterCell={filterConfig[column.filterType].filterCell}
                      cell={column.cell}
                    />
                  );
                }
              })}


          </Grid>
        </ExcelExport>

        {openSidePanel && (
          <Window
            title={`ESOI #: ${editItem.item}`}
            onClose={handleCancelEdit}
            visible={openSidePanel}
            className='position-fixed roboto-15-400'
            initialHeight={window.innerHeight}
            initialWidth={450}
            initialLeft={window.innerWidth - 450}
            style={{ borderRadius: '12px' }}
          >
            <div className='roboto-15-400'>
              <b className='roboto-15-700' style={{ fontSize: '23px' }}>SPECIAL TEXT</b>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '24px'}}>
                {/* <div><b className='roboto-15-700'>{`SO #: `}</b></div>
                <div>{editItem.orderNumber}</div> */}
                <div><b className='roboto-15-700'>{`ESOI #: `}</b></div>
                <div>{editItem.item}</div>
              </div>
            </div>
            <div class="container-fluid" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#EBEBEB', padding: '16px'}}>
              <div className="info-row" style={{borderTop: '1px solid #CECECE'}}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Description: '}</b>{editItem.descText}</div>
            </div>
            <div class="container-fluid" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
              <div style={{ marginTop: '16px' }}><b className='roboto-15-700'>Special text:</b></div>
              <div style={{ marginBottom: '16px', border: '1px solid #CECECE', borderRadius: '5px' }}>{editItem.orderNotesSpecialFeatures}</div>
            </div>
          </Window>
        )}

      </div>
    </div>
  );
}

export default ReferenceOrdersGrid;