import React, { useState, useEffect } from 'react';
import { GetCurrentRoute } from '../services/GetCurrentRoute';
import {Button} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import { getCurrentLine, setCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import { format, startOfWeek, endOfWeek, addDays } from 'date-fns';
import { formatDate } from '../Utilities/utils';

const PageHeader = () => {
  const currentPage = GetCurrentRoute();

  const textRoutes = {
    '':'Design Scheduler ', 
    'referenceorders': 'Reference Orders',
    'my-work': 'My assigned work',
    'dashboard': 'Dashboard',
    'historic': 'Historic',
    'changeorders': 'Change Orders and Shipdate Changes ' + getCurrentLine().ProdFamilyName,
    'drafthours': 'Drafthours & Capacity ' + getCurrentLine().ProdFamilyName,
  };

const [filterOptions, setFilterOptions] = useState([])
const { token } = useProfileData(false);
const [prodFamilyIds, setProdFamilyIds] = useState([]);
const [prodFamilyNames, setProdFamilyNames] = useState([]);

// current draft due week
const today = new Date();
const startDate = startOfWeek(today, { weekStartsOn: 1 }); 
const endDate = addDays(startDate, 4);

// Format dates (MM/DD)
// const formattedStartDate = format(startDate, 'MM/dd');
// const formattedEndDate = format(endDate, 'MM/dd');
const formattedStartDate = formatDate(startDate, false, 1);
const formattedEndDate = formatDate(endDate, false, 1);

const [buildWeeks, setBuildWeeks] = useState([]);
const [lastUpdateDate, setlastUpdateDate] = useState([]);

const getLastFridayOfNextYear = () => {
  const currentYear = new Date().getFullYear(); 
  const nextYear = currentYear + 1;
  const date = new Date(nextYear, 11, 31); 
  const dayOfWeek = date.getDay(); 
  const diff = 5 - dayOfWeek; 
  date.setDate(date.getDate() + diff); 
  return date;
};

const fetchBuildWeekCapacity = async () => {
  if (token) {
    try {

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const lastFridayOfYear = getLastFridayOfNextYear();
      
      //get all available build weeks
      const response = await axios.get(
        `/getBuildWeeks`,
        {
          params: {
            buildWeek: null,
            dateStart: currentDate.toISOString(),
            dateEnd: lastFridayOfYear.toISOString()
          },
          headers: {
            Authorization: token,
          },
        }
      );

      //get closer NewAdjustedDraftWeek 
      let closestDraftDate = null;
      let closestDraftDateDiff = Infinity;

      response.data.forEach(item => {
        const draftDate = new Date(item.newAdjustedDraftWeek);
        const diff = Math.abs(currentDate - draftDate);
        if (diff < closestDraftDateDiff) {
          closestDraftDate = item;
          closestDraftDateDiff = diff;
        }
      });
      
      setBuildWeeks(closestDraftDate);                

    } catch (error) {
      console.error("Error fetching data:", error);

    }
  } else {
    console.error("Error fetching data: Token not available");
  }
};

const fetchLastUpdateDate = async () => {
  if (token) {
    try {      
      //get all available build weeks
      const response = await axios.get(
        `/GetLastUpdateDate`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      setlastUpdateDate(formatDate(response.data, true));                

    } catch (error) {
      console.error("Error fetching data:", error);

    }
  } else {
    console.error("Error fetching data: Token not available");
  }
};

useEffect(() => {
  fetchBuildWeekCapacity();
  fetchLastUpdateDate();  
}, [token]);

  const [lineName, setLineName] = useState(getCurrentLine().ProdFamilyName);
  const [title, setTitle] = useState( textRoutes[currentPage] + ' ' + getCurrentLine().ProdFamilyName);
  const [showRingingIcon, setShowRingingIcon] = useState(false);

  useEffect(() => {
    if (currentPage !== ''){
      setTitle(textRoutes[currentPage]); 
    }
    else {
      setTitle('Design Scheduler ' + getCurrentLine().ProdFamilyName);      
    }
    
    
  }, [currentPage, title, lineName]);

  const fetchChangeOrders = async () => {    
    try {                     
      if (token) {
        const response = await axios.get(
          `/getChangeOrdToday/`,
          {
              headers: {
                  Authorization: token,
              },
          }
      );

        if(response.data !== null)        
          setShowRingingIcon(response.data);                  
        else
          setShowRingingIcon(false);
      }
      
    } catch (error) {
        setShowRingingIcon(false);
    }
  };

  //Verify if there are change orders
  useEffect(() => {
    if (currentPage === ''){
      fetchChangeOrders();
    }        
  }, [currentPage, lineName, token]);

  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      
      setLineName(newCurrentLine.ProdFamilyName);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const notificationIcon = [
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.179199 13.5879V12.0879H1.6792V6.83789C1.6792 5.80039 1.9917 4.87852 2.6167 4.07227C3.2417 3.26602 4.0542 2.73789 5.0542 2.48789V1.96289C5.0542 1.65039 5.16357 1.38477 5.38232 1.16602C5.60107 0.947266 5.8667 0.837891 6.1792 0.837891C6.4917 0.837891 6.75732 0.947266 6.97607 1.16602C7.19482 1.38477 7.3042 1.65039 7.3042 1.96289V2.48789C8.3042 2.73789 9.1167 3.26602 9.7417 4.07227C10.3667 4.87852 10.6792 5.80039 10.6792 6.83789V12.0879H12.1792V13.5879H0.179199ZM6.1792 15.8379C5.7667 15.8379 5.41357 15.691 5.11982 15.3973C4.82607 15.1035 4.6792 14.7504 4.6792 14.3379H7.6792C7.6792 14.7504 7.53232 15.1035 7.23857 15.3973C6.94482 15.691 6.5917 15.8379 6.1792 15.8379ZM3.1792 12.0879H9.1792V6.83789C9.1792 6.01289 8.88545 5.30664 8.29795 4.71914C7.71045 4.13164 7.0042 3.83789 6.1792 3.83789C5.3542 3.83789 4.64795 4.13164 4.06045 4.71914C3.47295 5.30664 3.1792 6.01289 3.1792 6.83789V12.0879Z" fill="#0097E0"/>
    </svg> ];
  
  const ringingIcon = [
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M0.5 6.83779C0.5 5.58779 0.778125 4.44092 1.33438 3.39717C1.89062 2.35342 2.6375 1.48779 3.575 0.800293L4.45625 2.00029C3.70625 2.55029 3.10938 3.24404 2.66563 4.08154C2.22188 4.91904 2 5.83779 2 6.83779H0.5ZM14 6.83779C14 5.83779 13.7781 4.91904 13.3344 4.08154C12.8906 3.24404 12.2938 2.55029 11.5438 2.00029L12.425 0.800293C13.3625 1.48779 14.1094 2.35342 14.6656 3.39717C15.2219 4.44092 15.5 5.58779 15.5 6.83779H14ZM2 13.5878V12.0878H3.5V6.83779C3.5 5.80029 3.8125 4.87842 4.4375 4.07217C5.0625 3.26592 5.875 2.73779 6.875 2.48779V1.96279C6.875 1.65029 6.98438 1.38467 7.20312 1.16592C7.42188 0.947168 7.6875 0.837793 8 0.837793C8.3125 0.837793 8.57812 0.947168 8.79688 1.16592C9.01562 1.38467 9.125 1.65029 9.125 1.96279V2.48779C10.125 2.73779 10.9375 3.26592 11.5625 4.07217C12.1875 4.87842 12.5 5.80029 12.5 6.83779V12.0878H14V13.5878H2ZM8 15.8378C7.5875 15.8378 7.23438 15.6909 6.94063 15.3972C6.64688 15.1034 6.5 14.7503 6.5 14.3378H9.5C9.5 14.7503 9.35313 15.1034 9.05938 15.3972C8.76562 15.6909 8.4125 15.8378 8 15.8378ZM5 12.0878H11V6.83779C11 6.01279 10.7063 5.30654 10.1187 4.71904C9.53125 4.13154 8.825 3.83779 8 3.83779C7.175 3.83779 6.46875 4.13154 5.88125 4.71904C5.29375 5.30654 5 6.01279 5 6.83779V12.0878Z" fill="#0097E0"/>
    </svg>
  ]

  const NotificationBadge = () => (
    <div style={{
        width: '16px',
        height: '16px',
        backgroundColor: '#FF4949',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '-6px',
        right: '-6px',
        zIndex: 1
      }}></div>
  );

  return (
    <div className="generalprops" style={{marginTop: '75px'}}>
      <div style={{gap: '24px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}> 
        <b className='roboto-19-700' style={{fontSize: '32px'}}>{title}</b>
        {(currentPage === '') && ( 
          <Button
            // title={textRoutes['changeorders']}
            // tag={Link} 
            className='secondary-button' 
            // to="/changeorders" 
            style={{ minWidth: '51px', position: 'relative' }}>
            {showRingingIcon ? ringingIcon : notificationIcon}
            {showRingingIcon && <NotificationBadge />}
          </Button>
         )}
      </div>
    {(currentPage === '' || currentPage === 'referenceorders') && (
      <div>
      <div className='roboto-15-400' style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px'}}>
        <div style={{color: '#4C4C4C'}}>Last update {lastUpdateDate}</div>
        {(currentPage === '') && (
          <div style={{alignItems: 'center', flexDirection: 'row', display: 'flex'}}>
            <b style={{paddingRight: '16px'}}>Current week {formattedStartDate}-{formattedEndDate}</b>
            Available hours: 
            <b style={{paddingRight: '16px'}}> {buildWeeks?.draftHoursAvailableCalc}</b>      
            <Button 
              title={textRoutes['drafthours']}
              tag={Link}
              className='primary-button roboto-15-500' 
              to="/drafthours"> 
            Drafthours & Capacity
            </Button>
          </div>
        )}
    </div>
    </div>   
    )}
  </div>
  );
};

export default PageHeader;
