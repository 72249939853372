import React, { useState, useEffect } from 'react';
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import useProfileData from '../../services/ProfileDataService';
import { formatDate } from '../../Utilities/utils';

const ChangeOrderCell = (props) => {
  const { dataItem } = props;
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const lockedStyles = {
    innerHeight: '100%',
    left: "40px", 
    zIndex: 1, 
    padding: "0px",
    background: 'inherit',
  };

  const COIcon = (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9316 1.33789C5.96364 1.33789 1.93164 5.36989 1.93164 10.3379C1.93164 15.3059 5.96364 19.3379 10.9316 19.3379C15.8996 19.3379 19.9316 15.3059 19.9316 10.3379C19.9316 5.36989 15.8996 1.33789 10.9316 1.33789ZM11.8316 14.8379H10.0316V9.43789H11.8316V14.8379ZM11.8316 7.63789H10.0316V5.83789H11.8316V7.63789Z" fill="#FF4949"/>
    </svg>
  );

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/changeorder/${dataItem.orderDetailId}/${dataItem.esoi}`, { state: { orderData: dataItem } });
  };

  // if (dataItem.mgmt === "Delta" && changesText != '' && todayChanges) {
  if (dataItem.mgmt === "Delta") {
    return (
      <td
        style={{ ...props.style, ...lockedStyles }} 
        className={props.className}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
          <button
            title = 'Click to see change details...'
            style={{ width: '100%', background: 'transparent' }}
            onClick={handleButtonClick}
          >
            {COIcon}
          </button>
      </td>
    );
  } else {
    return <td></td>;
  }
};

export default ChangeOrderCell;
