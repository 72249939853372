import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from '../axiosConfig';
import { Grid, GridColumn, getSelectedState} from '@progress/kendo-react-grid';
import { getter } from "@progress/kendo-react-common";
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import '@progress/kendo-theme-default/dist/all.css';
import './GeneralGridStyle.css';
import SidePanelNewO from './SidePanelNewO';
import useProfileData from '../services/ProfileDataService';
import CustomFilter from '../components/customizedFilters/CustomFilter';
import Loader from './Loader';
import Toast from './ToastNotification';
import { truncateEmail } from '../Utilities/utils';
import { useMsal } from "@azure/msal-react";
import Modal from './Modal';
import columnFieldMapping from '../Utilities/columnFieldMapping'
import { ExcelExport } from "@progress/kendo-react-excel-export";
import instance from '../axiosConfig';
import LogOutService from '../services/LogOutService';
import DraftHoursCellStyle from './customizedColumns/DraftHoursCellStyle';
import { getCurrentLine,subscribeToCurrentLineChanges  } from '../services/CurrentLineService';

const DATA_ITEM_KEY = "scheduleId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const DraftHoursGrid = ({ columns = [], dateRange,
  filter, setFilter,
  prevFilter, setPrevFilter,
  currentFilter, setCurrentFilter, onRemoveAll,
  setRemoveFilter, dropdownFilters, setDropdownFilters, removeFilter, disabled, 
  exportExcel, setExportExcel, 
  selectedOptions, setSelectedOptions, onDrafthoursEdit,
}) => {

  const [scheduleData, setScheduleData] = useState({ TotalRows: 0, Items: [] });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [sort, setSort] = useState([{ field: "ProductionBuildWeek", dir: "asc" }]);
  const [page, setPage] = useState({ skip: 0, take: pageSize });

  const { token } = useProfileData(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [department, setDepartment] = useState(getCurrentLine().PlantProdFamilyId);

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const {accounts, instance } = useMsal();  
  const viewAlias = 3;

  const fetchData = async () => {
    
    try {
      setLoading(true);            
      if (token) {
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent(sort?.[0]?.field || 'ProductionBuildWeek')}`,
          {
            plantProdFamilyId: department,
            dateColumn: 'BuildWeek',
            sort: sort?.[0]?.dir || 'asc',
            skip: page.skip,
            take: page.take,
            startDate: null,
            endDate: null,
            filter: JSON.stringify(filter, null, 2),
            viewAlias: viewAlias,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const usersWithIndex = response.data.items.map((user, index) => ({
          ...user,
          rowIndex: index + 1 + page.skip,
        }));

        setScheduleData({
          TotalRows: response.data.TotalRows,
          Items: usersWithIndex,
        });

        setUsers(usersWithIndex);
        setTotalRows(response.data.totalRows);
        setLoading(false);

      }
    } catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);        

      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }
      setLoading(false);
    }
  };

  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setDepartment(newCurrentLine.PlantProdFamilyId);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast + 1);


    fetchData();
  }, [token, page.skip, page.take, sort, dateRange, filter, department]);

  useEffect(() => {
    setPage({ skip: 0, take: pageSize });
    setCurrentPage(1);
  }, [dateRange, sort, filter]);

  const [filtro, setFiltro] = useState(null)

  // **************Static filters approach**************
  const [filterOptions, setFilterOptions] = useState([])

  const filterConfig = {
    text: "text",
    number: "numeric",
    boolean: "boolean",
    date: "date",
    dropdown: {
      filterCell: (props) => {
        const { field } = props;
        return (
          <CustomFilter
            {...props}
            data={users}
            field={field}
            currentFilterField={field}
            onSelectionChange={handleCustomFilterChange}
            onRemoveAll={onRemoveAll}
            selectedOptions2={currentFilter}
            selectedFilter={filtro}
            removeFilter={removeFilter}
            prevFilter={prevFilter}
            filter={dropdownFilters}
            endPointData={filterOptions}
            viewOption={viewAlias}
          />
        );
      }
    }
  };

  const handlePriorityUpdate = async (unmarkSuccess) => {
    if (unmarkSuccess == true) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'Order unmarked successfully', type: 'success' });
    }
    await fetchData();
  };

  const handleAssignOrder = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order assigned successfully', type: 'success' });

    await fetchData();
  };

  const handleUnassignOrder = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order unassigned successfully', type: 'success' });

    await fetchData();
  };

  const handleAssignError = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'An error occurred while assigning the order', type: 'error' });
  };
  
  const handleCustomFilterChange = (selectedOptions, currentFilterField) => {
    
    setFilter((prevFilter) => {
      if (!prevFilter) {
        // Si no hay un filtro existente, crea uno nuevo con la selección actual
        return {
          logic: "or",
          filters: [
            ...selectedOptions.map((option) => ({
              field: currentFilterField,
              operator: "contains",
              value: option,
            })),
          ],
        };
      } else {
        // Si ya existe un filtro, actualízalo con la selección actual
        const updatedFilters = [
          ...prevFilter.filters.filter((filter) => filter.field !== currentFilterField),
          ...selectedOptions.map((option) => ({
            field: currentFilterField,
            operator: "contains",
            value: option,
          })),
        ];

        return {
          ...prevFilter,
          filters: updatedFilters,
        };
      }
    });
    setFiltro(currentFilterField);
    setCurrentFilter(selectedOptions);
    setRemoveFilter(false);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    allFields.unshift(currentFilterField);
    const allFields2 = [...new Set(allFields)];
    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters);
  };


  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const newPage = {
      skip: event.page.skip,
      take: targetEvent.value === 'All' ? totalRows : event.page.take,
    };

    setPage(newPage);
    setCurrentPage(newPage.skip / pageSize + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.filter);
  };

  const filteredUsers = filterBy(users, filter);
  const sortedUsers = orderBy(filteredUsers, sort);

  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns], [sortedUsers]);

  const [selectedColumnFields, setSelectedColumnFields] = useState(columns.map(column => column.field));

  useEffect(() => {
    setSelectedColumnFields(columns.map(column => column.field));
  }, [columns]);

  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState();
  const [data, setData] = React.useState(sortedUsers);

  const enterEdit = async (item) => {
    
    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);

};

  const handleDesignerDetailHrsChange = (newDesignerDetailHrs,) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumber: newDesignerDetailHrs      
    }));
  };

  const handleDesignerCheckerHrsChange = (newDesignerCheckerHrs) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumberChecker: newDesignerCheckerHrs
    }));
  };


  const handleSubmit = (event) => {
    let newData = data.map((item) => {
      if (event.item === item.item) {
        item = {
          ...event,
        };
      }
      return item;
    });
    setData(newData);



    setOpenForm(false);
  };

  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;

    const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
    try {
      const response = await axios.get(downloadUrl, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob',
      });

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const imageUrl = URL.createObjectURL(blob);

    const imageSrc = imageUrl;

    if (imageSrc == null) {
      setProfileImgAssigned('/DefaultProfile.png');
    }
    else {
      setProfileImgAssigned(imageSrc);
    }

    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          401: 'Expired Token',
          500: 'The file does not exist on the blob storage'
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });
    }
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSortChange = (e) => {
    setSort(e.sort || []);
  };

  const handleModalError = async (image, userName) => {
    downloadFile(image);
    setName(userName);
    setModalVisible(true);

    await fetchData();
  };

  const _export = React.useRef(null);
  const _grid = React.useRef();

  const _formattedDate = (() => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  })();

  const excelExport = () => {
    if (_export.current !== null && exportExcel === true) {      

      const today = new Date();
      // _export.current.save(users, _grid.current.columns);
      _export.current.save(users);
    }
  };
  
  useEffect(() => {
    excelExport();
    setExportExcel(false);
  }, [exportExcel === true]);

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 270);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 270;
      setGridHeight(newGridHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment


  // Handle options selected in the selection column
  const [dataState, setDataState] = useState([]);
  useEffect(() => {
    if (users) {
      if(users.length > 0)
      {
        const updatedDataState = users.map((dataItem) =>
          Object.assign(
            {
              selected: false,
            },
            dataItem
          )
        );
        setDataState(updatedDataState);
      }
      else
      {
        setDataState([]);
      }
    }
  }, [users]);
  
  const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  useEffect(() => {
    const selectedItems = dataState.filter(item => selectedState[item[DATA_ITEM_KEY]]); 
    setSelectedOptions(selectedItems); //selectedItems stores the selected options information
  }, [selectedState, dataState]);
  // Handle options selected in the selection column

  // Update the grid when the form is submitted
  const showToastSuccess = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Drafting week(s) updated successfully', type: 'success' });
  };

  useEffect(() => {
    showToastSuccess();
    setSelectedState({});
    fetchData();
  }, [onDrafthoursEdit]);
  // Update the grid when the form is submitted

  return (
    <div style={{ position: 'relative' }}>  
      {loading && (
        <Loader></Loader>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
        image= {profileImgAssigned}
      />

      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}

      <div style={{ height: gridHeight, marginBottom: '15px', position: 'relative' }}>
        <ExcelExport ref={_export} fileName={`newOrders_${_formattedDate}.xlsx`}>
          <Grid
            className='roboto-15-400'
            // data={(users || [])}
            data={dataState.length > 0 ? dataState.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })) : []}
            // dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            skip={page.skip}
            take={page.take}
            total={totalRows}
            pageable={{
              buttonCount: 4,
              type: "input",
              pageSizes: [50, 150, 200, totalRows].filter(num => num <= totalRows),
              pageSize: pageSize,
            }}
            onPageChange={pageChange}
            sortable={true}
            filterable={true}
            filter={filter}
            onFilterChange={handleFilterChange}
            resizable
            reorderable
            sort={sort}
            onSortChange={handleSortChange}
            style={{
              borderRadius: '8px',
              display: 'flex',
              margin: '15px',
              height: 'inherit'
            }}
            ref={_grid}
          >
            {/* <GridColumn
              className='roboto-15-400'
              reorderable={false}
              sortable={false}
              filterable={false}
              editable={false}
              field="rowIndex"
              title="#"
              width="40px"
            /> */}
            <GridColumn
              filterable={false}
              // locked={true}
              editable={false}
              field={SELECTED_FIELD}
              width="43px"
              headerSelectionValue={
                dataState.length > 0 && dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
              }             
            />
            {selectedColumns
              .filter((column) => column.visible)
              .map((column) => {
                return (
                    <GridColumn
                      className='roboto-15-400'
                      sortable={true}
                      key={column.field}
                      field={column.field}
                      title={column.title}
                      headerCell={column.header}
                      width={column.minWidth}
                      filter={filterConfig[column.filterType]}
                      format="{0:d}"
                      filterCell={filterConfig[column.filterType].filterCell}
                      cell={(props) => (
                        <DraftHoursCellStyle
                          {...props}
                        />
                      )}
                    />
                  );
                }
              )}
          </Grid>
        </ExcelExport>
      </div>

      {openForm && editItem && editItem.orderDetailId && (
        <SidePanelNewO
          cancelEdit={handleCancelEdit}          
          item={editItem}
          disabled= {true}
        />
      )}  
    </div>
  );
}

export default DraftHoursGrid;