import React, { useState, useEffect } from 'react';
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { useNavigate } from 'react-router-dom';
import { getCurrentLine, subscribeToCurrentLineChanges } from '../../services/CurrentLineService';
import { MAVERICK_LINE } from '../../Utilities/constants';

const CodeSplitCell = (props) => {
  const { dataItem } = props;
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const lockedStyles = {
    innerHeight: '100%',
    left: "40px", 
    zIndex: 1, 
    padding: "0px",
    background: 'inherit',
  };

  const navigate = useNavigate();

  const handleLinkClick = () => {    
    
    //Maverick validation
    if(getCurrentLine().PlantProdFamilyId !== MAVERICK_LINE)
      navigate(`/codesplit/${dataItem.orderDetailId}/${dataItem.esoi}`, { state: { orderData: dataItem } });    
  };  

  //Notice changes on current Line service
  const [lineName, setLineName] = useState(getCurrentLine().ProdFamilyName);

  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setLineName(newCurrentLine.ProdFamilyName);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <td
      style={{ ...props.style, ...lockedStyles }} 
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
      title={dataItem.code}
    >
      <a style={{textDecoration: 'underline', color: '#0097E0', background: 'transparent', cursor: 'pointer', padding: '8px 12px' }}
        onClick={handleLinkClick}>
        {dataItem.code}
      </a>
    </td>
  );

};

export default CodeSplitCell;
