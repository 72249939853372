import React from 'react';
import { formatDate } from '../../Utilities/utils';

const DraftHoursCellStyle = (props) => {
    const { dataItem, field } = props;
    const { draftHoursAvailableCalc } = dataItem;
  
    let backgroundColor = ''; // Default color

  if (field === 'productionBuildWeek'){
    backgroundColor = 'rgba(255, 244, 230, 0.5)';
  }
  else {
    if (draftHoursAvailableCalc < 0) {
      backgroundColor = 'rgba(254, 224, 210, 0.5)'; // Red for negative numbers
    } else if (draftHoursAvailableCalc > 0 && draftHoursAvailableCalc < 11) {
      backgroundColor = 'rgba(253, 255, 187, 0.5)'; // Yellow for numbers less than 11
    } else if (draftHoursAvailableCalc === 0) {
      backgroundColor = 'rgba(222, 254, 203, 0.5)'; // Green for 0
    }
  }
  
    // Función para obtener el color de fondo basado en el valor del campo
    const getFontColor = (value) => {
        const fontColorMap = {
        '-1': '#CE3B2B', // Red for negative numbers
        'default': '' // Default color
        };

        if (value < 0) {
        return fontColorMap['-1'];
        } else {
        return fontColorMap['default'];
        }
    };

    // Obtener el color de fondo basado en el valor del campo
    const color = getFontColor(dataItem[field]);

    // Verificar si se necesita formato de fecha
    const isDateField = field === 'productionBuildWeek' || field === 'newAdjustedDraftWeek' || field === 'draftCompDate';
    const boldStyle = field === 'capacity' || field === 'draftHoursAvailableCalc';
    
    return (
      <td className='roboto-15-400'
        style={{ 
            backgroundColor, 
            color: color,
            fontWeight: boldStyle ? 'bold' : 'default'
        }}>
        {isDateField ? formatDate(dataItem[field], false, 1) : (dataItem[field] !== undefined ? dataItem[field] : '')}
      </td>
    );
  };

export default DraftHoursCellStyle;