import React, { useState, useEffect, useRef } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import './TabStripComponent.css'
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon, searchIcon, circleIcon } from "@progress/kendo-svg-icons";
import axios from '../axiosConfig';
import Toast from './ToastNotification';
import useProfileData from '../services/ProfileDataService';
import './GeneralGridStyle.css';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { InputPrefix, TextBox } from "@progress/kendo-react-inputs";
import { getCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import Loader from './Loader';
import './tabComponentCodeSplit.css';
import { useNavigate  } from 'react-router-dom';


  const CodeSplitGrid = ({item, formatNumber, setCodeSplitWhole, codeHeight, handleShowMessage}) => {
    const [sort, setSort] = useState([]);
    const [data, setData] = useState([]);
    const { token } = useProfileData();
    const [loading, setLoading] = useState(true);
    const [originalData, setOriginalData] = useState([]);
    const [codeSplit, setCodeSplit] = useState([]);    
    const [selectedItems, setSelectedItems] = useState([]);
    const navigate= useNavigate();

    
    useEffect(() => {
        fetchCodeSplit();        
      }, [formatNumber, token]);

    const fetchCodeSplit = async () => {
        setLoading(true);           

        if (token) {
            try {              
            if(formatNumber){              
              const response = await axios.get(
                  `/getCodeStringSplit?&formatNumber=${encodeURIComponent(formatNumber)}&codeString=${encodeURIComponent(item.code)}&specialNotes=${encodeURIComponent(item.specialText)}&soNumber=${encodeURIComponent(item.so)}`,
                  // `/getCodeStringSplit?&formatNumber=${encodeURIComponent(300)}&codeString=${encodeURIComponent('DPSA130B4EHBMD0E06S2BJ00000V0000000000000002AAD00000BBBAAAS0000AA000002C200101011010100X200000A00000000000000000H30000B00E00000000Q060000000000CNDPLNSAFBL1EVSRAS0000000000000000000000000000000000000000000000000000000001740680440481200640000000000000000000000000000000000000000000000000000000000518400002004884001890000000000000180000009000005105298006709500000000000001051670000001500015630560000000000000000000000000000000000000000000000028503000278000000000000000010000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000')}&specialNotes=${encodeURIComponent('67 Phase 9 MCS  Please see NPI Design Special; Special ')}&soNumber=${encodeURIComponent(5012490)}`,
                  {
                  headers: {
                      Authorization: token,
                  },
                  }
              );
            
              setCodeSplit(response.data);
              setCodeSplitWhole(response.data.codeStringSplitWhole);

              const { codeStringCodeItems, codeStringCodeValues, codeStringCodeDescriptions } = response.data;

              const codeSplitResponse = codeStringCodeItems.map((item, index) => ({
                  Item: item,
                  Value: codeStringCodeValues[index],
                  Description: codeStringCodeDescriptions[index],
              }));

              setData(codeSplitResponse);
              setOriginalData(codeSplitResponse);

              setLoading(false);
            }
            else setLoading(false);           
            } 
            catch (error) {
                setLoading(false);
                handleShowMessage(error.response.data ,"error");
            }
        }
    };

    const handleFindReferenceOrders = () =>{   
      if(formatNumber)   
        navigate('/referenceorders', { state: { formatNumber, selectedItems } });
    }

    const filterData = (e) => {
      let value = e.target.value;
    
      if (value === "") {
        setData(originalData);
      } else {
        let filter = {
          logic: "or",
          filters: [
            {
              field: "Item",
              operator: "contains",
              value: value,
            },
            {
              field: "Value",
              operator: "contains",
              value: value,
            },
            {
              field: "Description",
              operator: "contains",
              value: value,
            },
          ],
        };
        setData(filterBy(originalData, filter));
      }
    };

    // Zoom adjustment
    const [zoomLevel, setZoomLevel] = useState(1);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 308 - codeHeight);

    const handleRowSelectionChange = (e, data, setData, setSelectedItems) => {
      const updatedData = data.map(item =>
        item === e.dataItem
          ? { ...item, selected: !item.selected } 
          : item
      );
      
      const updatedSelectedItems = updatedData.filter(item => item.selected);
      setSelectedItems(updatedSelectedItems);       
      setData(updatedData); 
    };
    
    const handleHeaderSelectionChange = (e, data, setData, setSelectedItems) => {
      const isAllSelected = e.nativeEvent.target.checked;
    
      // select all elements but the first one
      const updatedData = data.map((item, index) => ({
        ...item,
        selected: isAllSelected && index !== 0,
      }));
    
      const updatedSelectedItems = isAllSelected
        ? updatedData.filter((item, index) => index !== 0)
        : [];
    
      setSelectedItems(updatedSelectedItems);
      setData(updatedData);
    };    

    useEffect(() => {
      const handleResize = () => {
        const viewportWidth = window.innerWidth;
        const windowWidth = window.outerWidth;
        const newZoomLevel = windowWidth / viewportWidth;
        setZoomLevel(newZoomLevel);

        const newGridHeight = window.innerHeight - 308 - codeHeight;
        setGridHeight(newGridHeight);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, [codeHeight]);
    // Zoom adjustment

    const warningIcon = [<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 20.5C7.40625 20.5 4.125 18.625 2.32812 15.5C0.53125 12.4141 0.53125 8.625 2.32812 5.5C4.125 2.41406 7.40625 0.5 11 0.5C14.5547 0.5 17.8359 2.41406 19.6328 5.5C21.4297 8.625 21.4297 12.4141 19.6328 15.5C17.8359 18.625 14.5547 20.5 11 20.5ZM11 5.5C10.4531 5.5 10.0625 5.92969 10.0625 6.4375V10.8125C10.0625 11.3594 10.4531 11.75 11 11.75C11.5078 11.75 11.9375 11.3594 11.9375 10.8125V6.4375C11.9375 5.92969 11.5078 5.5 11 5.5ZM9.75 14.25C9.75 14.9531 10.2969 15.5 11 15.5C11.6641 15.5 12.25 14.9531 12.25 14.25C12.25 13.5859 11.6641 13 11 13C10.2969 13 9.75 13.5859 9.75 14.25Z" fill="#FF4949"/>
    </svg>];

    return (
      <div className='splitCode_Grid-container' style={{height: gridHeight}}>
        {loading && <Loader />}

        <Grid
          data={orderBy(data, sort)}
          className="roboto-15-400 splitCode_Grid"
          style={{
            height: `${gridHeight - 5}px`,
          }}
          total={data?.length || 0}
          sortable={true}
          sort={sort}
          onSortChange={(e) => setSort(e.sort)}
          selectedField="selected"
          onSelectionChange={(e) => {
            // first row not selectable
            if (e.dataItem !== data[0]) {
              handleRowSelectionChange(e, data, setData, setSelectedItems);
            }
          }}
          onHeaderSelectionChange={(e) => handleHeaderSelectionChange(e, data, setData, setSelectedItems)}
          selectable={{
            mode: 'multiple',
            checkboxOnly: true,
          }}
        >
          <GridToolbar>
            <div className="roboto-15-400 splitCode_toolbar-container">
              <TextBox
                className="roboto-15-400 splitCode_search-input"
                onChange={filterData}
                placeholder="Search..."
                prefix={() => (
                  <InputPrefix>
                    <SvgIcon icon={searchIcon} />
                  </InputPrefix>
                )}
              />
              <div className="d-flex splitCode_find-button">
                <Button
                  onClick={handleFindReferenceOrders}
                  className="return-button blue-text w-100 fs-15"
                >
                  Find similar reference orders
                </Button>
              </div>
            </div>
          </GridToolbar>

          {/* Checkbox column for selection */}
          <Column field="selected" width="50px" headerSelectionValue={data?.every(item => item.selected)} />

          <Column
            className="roboto-15-400"
            reorderable={false}
            sortable={false}
            filterable={false}
            editable={false}
            field="Item"
            title="Code item"
            width="100px"
          />
          <Column field="Value" title="Code value" editor="text" />
          <Column field="Description" title="Description" editor="text" />
        </Grid>
      </div>
    );
};

const TabComponentCodeSplit = ({item}) => {
  const rowStyle= {margin: '0px 16px',  display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'};

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 170);
  const [infoHeight, setInfoHeight] = useState(gridHeight - 210);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 170;
      setGridHeight(newGridHeight);
      const newInfoHeight = newGridHeight - 210;
      setInfoHeight(newInfoHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  // Main endpoint
  const { token } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);

  const [lineName, setLineName] = useState(getCurrentLine().ProdFamilyName);
  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setLineName(newCurrentLine.ProdFamilyName);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const [formatNumber, setFormatNumber] = useState();

  const fetchFormatNumber = async () => {
    if (token) {
      try {                      

        const endpointUrl = `/getFormatNumber?&esoiNumber=${encodeURIComponent(item?.esoi)}&PCL=${encodeURIComponent(item?.pcl)}`;

          const response = await axios.get(endpointUrl, {
            headers: {
              Authorization: token,
            }
        });

        setFormatNumber(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    else
    {
      console.error("Error fetching data:");
    }
  };

  useEffect(() => {
    fetchFormatNumber();
  }, [lineName, token]);

  const [show, setShow] = useState(false);  
  const onClick = () => {
    setShow(!show);
  };

  const handleShowMessage = async (text, state) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });  
  };

  const onCopyCode = () => {
    navigator.clipboard.writeText(item.code).then(() => {      
      handleShowMessage('Code copied to clipboard','success');
    }).catch(err => {
      handleShowMessage('Failed to copy code: ' + err, 'error');
    });
  };

  const arrow = [
    <div><svg
    transform={show ? 'rotate(-180)' : ''}
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="#1C2325"
  >
    <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325" />
  </svg></div> ];

  const [codeSplitWhole, setCodeSplitWhole] = useState([]);
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
      if (divRef.current && show) {
          setDivHeight(divRef.current.clientHeight - 22);
      }
      else if (divRef.current && show === false) {
        setDivHeight(0);
      }
  }, [divRef, show]);

  return (
    <div>
    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end', position: 'absolute', top: '75px', left: '30px', gap: '8px'}}> 
      <p className='roboto-19-700' style={{fontSize: '32px'}}>{'SO'}</p>
      <p className='roboto-19-400' style={{fontSize: '32px'}}>{item?.so}</p>
      <p className='roboto-19-700' style={{fontSize: '32px'}}>{'ESOI'}</p>
      <p className='roboto-19-400' style={{fontSize: '32px'}}>{item?.esoi}</p>
    </div>
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <TabStrip
        selected={0}
        style={{ height: gridHeight, border: 'none', display: 'flex',}}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <div className= 'roboto-15-400' style={{...rowStyle, margin: '16px'}}>
              <Button 
                tag={Link}
                className='secondary-button roboto-15-500 blue-text' 
                to="/">
                <SvgIcon icon={chevronLeftIcon} size={'large'} color='#0097E0'></SvgIcon>
                Return
              </Button>
              <Button                 
                className='primary-button roboto-15-500' 
                onClick={onCopyCode}>
                <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.625 0.628906H13.4688C13.9375 0.628906 14.4453 0.863281 14.7969 1.21484L17.4141 3.83203C17.7656 4.18359 18 4.69141 18 5.16016V13.7539C18 14.8086 17.1406 15.6289 16.125 15.6289H8.625C7.57031 15.6289 6.75 14.8086 6.75 13.7539V2.50391C6.75 1.48828 7.57031 0.628906 8.625 0.628906ZM2.375 5.62891H5.5V8.12891H3V18.1289H10.5V16.8789H13V18.7539C13 19.8086 12.1406 20.6289 11.125 20.6289H2.375C1.32031 20.6289 0.5 19.8086 0.5 18.7539V7.50391C0.5 6.48828 1.32031 5.62891 2.375 5.62891Z" fill="#F6F6F6"/>
                </svg>
                Copy code
              </Button>
            </div>
            <div className='roboto-15-400'  ref={divRef} style={{...rowStyle, margin: '16px', height: 'auto', wordWrap: 'anywhere', overflow: 'hidden', boxSizing: 'border-box', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <button title = 'Click to display/hide code' style={{background: 'transparent', width: '19px', marginRight: '8px', height: '19px', display: 'flex', alignItems: 'center'}} onClick={onClick}>{arrow}</button>
                {show && (
                    <p><b>Code: </b>{codeSplitWhole}</p>
                )}
                {show === false && (
                    <p><b>Code: </b>{codeSplitWhole.slice(0, 70) + '...'}</p>
                )}
            </div>
            <div className= 'roboto-15-400' style={{...rowStyle, margin: '16px', marginTop: '0px'}}>
              <CodeSplitGrid
                item = {item}
                formatNumber = {formatNumber}
                setCodeSplitWhole={setCodeSplitWhole}
                codeHeight = {divHeight}
                handleShowMessage = {handleShowMessage}
              ></CodeSplitGrid>
            </div>
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
    </div>
  );
};

export default TabComponentCodeSplit;
