import React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import './SidePanel.css';

const SidePanelNewO = (props) => {
  const { visible, cancelEdit, item } = props;

  return (
    <Window
      title={`ESOI #: ${item.esoi}`}
      onClose={cancelEdit}
      visible={visible}
      className='position-fixed roboto-15-400'
      initialHeight={window.innerHeight}
      initialWidth={450}
      initialLeft={window.innerWidth - 450}
      style={{ borderRadius: '12px' }}
    >
      <div className='roboto-15-400'>
        <b className='roboto-15-700' style={{ fontSize: '23px' }}>SPECIAL TEXT</b>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '24px'}}>
          <div><b className='roboto-15-700'>{`SO #: `}</b></div>
          <div>{item.so}</div>
          <div><b className='roboto-15-700'>{`ESOI #: `}</b></div>
          <div>{item.esoi}</div>
        </div>
      </div>
      <div class="container-fluid" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#EBEBEB', padding: '16px'}}>
        <div className="info-row" style={{borderTop: '1px solid #CECECE'}}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Description: '}</b>{item.description}</div>
      </div>
      <div class="container-fluid" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
        <div style={{ marginTop: '16px' }}><b className='roboto-15-700'>Special text:</b></div>
        <div style={{ marginBottom: '16px', border: '1px solid #CECECE', borderRadius: '5px' }}>{item.specialTextTrunc}</div>
      </div>
    </Window>
  );
};

export default SidePanelNewO;