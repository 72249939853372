import React, { useState, useEffect } from 'react';
import CustomDatePicker from './CustomDatePicker'; 
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";
import dayjs from 'dayjs';

function DateRangePicker({ onDateRangeChange, emptyDate, setEmptyDate,startDate, setStartDate, endDate,  setEndDate}) {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const savedStartDate = localStorage.getItem('startDate');
    const savedEndDate = localStorage.getItem('endDate');

    if (savedStartDate && savedEndDate) {
      setStartDate(dayjs(savedStartDate));
      setEndDate(dayjs(savedEndDate));
    }
  }, []);

  // Save values to local storage whenever startDate or endDate changes
  useEffect(() => {
    if (startDate) {
      localStorage.setItem('startDate', startDate.toISOString());
    }
    if (endDate) {
      localStorage.setItem('endDate', endDate.toISOString());
    }
  }, [startDate, endDate]);

  const handleStartDateSelect = (date) => {
    setStartDate(dayjs(date).startOf('week'));
    setEndDate(dayjs(date).endOf('week').subtract(1,'day'));
    setErrorMessage('');
    onDateRangeChange(dayjs(date).startOf('week'), dayjs(date).endOf('week').subtract(1,'day'));
    setEmptyDate(false);
  };

  const handleEndDateSelect = (date) => {
    if (startDate && date.isBefore(startDate, 'day')) {
      setErrorMessage('The selected end week must start one or several weeks after the start week.');
    } else {
      setEndDate(dayjs(date).endOf('week').subtract(1,'day'));
      setErrorMessage('');
      onDateRangeChange(startDate, dayjs(date).endOf('week').subtract(1,'day'));
      setEmptyDate(false);
    }
  };

  const clearDates = () => {
    onDateRangeChange(setStartDate(null), setEndDate(null));
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    setEmptyDate(true);  
  };
  
  

  return (
    <div className= 'roboto-15-700' style={{justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px'}}>
      {/* {'Drafting due'} */}
      <div 
        style={{
          marginLeft: '24px',
          padding: '0px 8px',
          gap: '8px',
          justifyContent: 'flex-end', 
          flexDirection: 'row', 
          display: 'flex', 
          alignItems: 'center', 
          height:'36px',
          background: 'white',
          borderRadius: '4px',
          filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.09))'
          }}>
        <div className= 'roboto-15-400' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '16px', borderRight: '1px solid #8c8c8c'}}>Drafting due weeks</div>
        <CustomDatePicker
          value={startDate}
          message={''}
          onDateSelect={handleStartDateSelect}
          endPicker= {false}
          empty= {startDate === null}
        />
        {'to'}
        <CustomDatePicker
          value={endDate}
          message={errorMessage}
          onDateSelect={handleEndDateSelect}
          endPicker= {true}
          empty= {endDate === null}
        />
        <Button
          onClick={clearDates} 
          style={{
              padding: '0px',
              width: '24px',
              height: '24px',
              borderRadius: '180px',
              backgroundColor: '#F2F2F2',
              border: 'none',
          }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
      </Button>
      </div>
    </div>
  );
}

export default DateRangePicker;



