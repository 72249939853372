import React from 'react';
import './codeCompareView.css';

const ComparisonRow = ({ label, value1, value2, isBold, isLastRow }) => {
  return (
    <div className={`my-work-card roboto-15-400 row-style ${isLastRow ? 'lastRow' : 'no-border'}`}>
      <div className="roboto-15-400 d-flex justify-content-between align-items-center card-row word-wrap-container">
        <div className="d-flex flex-row align-items-center justify-content-between card-content">
          <div className='w-10'>{isBold ? <b>{label}</b> : label}</div>
          <div className='w-40'>{value1}</div>
          <div className='w-40'>{value2}</div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonRow;