import * as React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const SpecialTextCell = (props) => {
  const { dataItem } = props;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  
  // Variant to manage Reference orders
  const getSpecialText = () => {  
    if (dataItem.specialText && dataItem.specialText.length > 0) {
      return dataItem.specialText;
    }

    if (dataItem.orderNotesSpecialFeatures && dataItem.orderNotesSpecialFeatures.length > 0) {
      return dataItem.orderNotesSpecialFeatures;
    }    
    return '';
  };
  
  const specialText = getSpecialText();

  return (
    // <td className='roboto-15-400' style={{ cursor: 'pointer' }} title={specialText}>
    //   {specialText.length > 20 ? `${specialText.substring(0, 20)}...` : specialText}
    // </td>
    <td
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
      title={specialText}
    >
    <a
      className='roboto-15-400'
      style={{ textDecoration: 'underline', color: '#0097E0', width: '100%', background: 'transparent', cursor: 'pointer' }}
      onClick={() => props.enterEdit(dataItem)}
    >
      {specialText?.length > 60
        ? `${specialText.substring(0, 60)}...`
        : specialText}
    </a>
  </td>
  );
};

export default SpecialTextCell;
