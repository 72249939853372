import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import './ColumnSelectorDialog.css'

const ColumnSelectorDialog = ({ visible, onClose, onSave, selectedColumns, allColumns, onReset }) => {
  const [checkboxState, setCheckboxState] = useState(
    allColumns.reduce((acc, column) => {
      acc[column.field] = column.visible;
      return acc;
    }, {})
  );

  const handleCheckboxChange = (field) => {
    setCheckboxState(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
    onSave(field);
  };

  const handleSave = () => {
    const updatedColumns = Object.keys(checkboxState).filter(field => checkboxState[field]);
    onSave(updatedColumns);
    onClose();
  };

  useEffect(() => {
    setCheckboxState(prevState => ({
      ...prevState,
      ...allColumns.reduce((acc, column) => {
        acc[column.field] = column.visible;
        return acc;
      }, {})
    }));
  }, [allColumns]);

  const handleCancel = () => {
    onClose();
  };

  const resetColumns = () => {
    setCheckboxState( 
      allColumns.reduce((acc, column) => {
        acc[column.field] = column.visible;
        return acc;
      }, {}));
    onSave(checkboxState);
    handleCheckboxChange();
    onReset(); 
  };

  const resetsvg = (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4.01162H7.586L9.414 2.20571L8 0.80957L5.171 3.60285L3.757 4.99899L5.171 6.39513L8 9.18841L9.414 7.79226L7.586 5.98636H8C11.313 5.98636 14 8.63943 14 11.9106C14 15.1818 11.313 17.8348 8 17.8348C4.687 17.8348 2 15.1818 2 11.9106H0C0 16.2728 3.582 19.8096 8 19.8096C12.418 19.8096 16 16.2728 16 11.9106C16 7.54838 12.418 4.01162 8 4.01162Z" fill="#0097E0"/>
    </svg>
  );

  return (
    visible && (
      <Dialog
        width={'385px'}
        onClose={onClose} 
        visible={visible}
        >
        <div className='roboto-19-700 mb-3 mt-2'>{'Table column configuration'}</div>
        <button
          className="roboto-15-500 secondary-button blue-text mb-2"
          onClick={resetColumns}
          style={{ border: 'none', alignItems: 'center'}}
        >
          {'Reset to predefined'}{resetsvg}
        </button>
        <div className='roboto-15-500' style={{marginBottom: '16px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
          <div>Column</div><div style={{marginRight: '16px'}}>View</div>
        </div>
        <div style={{overflowY: 'scroll', maxHeight: window.innerHeight - 250, paddingRight: '8px'}}>
        {allColumns.map(column => (
          <div
            className='roboto-15-400'
            key={column.field} 
            style={{marginBottom: '16px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignContent: 'baseline'}}>
              <div>{column.title}</div>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={checkboxState[column.field]}
                  onChange={() => handleCheckboxChange(column.field)}
                  style={{ display: 'none' }}
                />
                <div className={`checkbox-icon ${checkboxState[column.field] ? 'checked' : ''}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="white"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                </div>
              </label>
          </div>
        ))}
        </div>
        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'flex-end', gap:'8px', marginTop: '16px'}}>
          <button className="secondary-button roboto-15-500 blue-text" onClick={handleCancel}>Cancel</button>
          <button className="primary-button roboto-15-500" onClick={handleSave}>Save</button>
        </div>
      </Dialog>
    )
  );
};

export default ColumnSelectorDialog;