import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/esm/Dropdown";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <div>           
            {/* <button onClick={() => handleLogout("popup")}>Sign out using Popup</button> */}
            {/* <button as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</button> */}
            <button 
                className="roboto-15-400 secondary-button bg-white" 
                onClick={() => handleLogout("redirect")}
                style={{gap: '8px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', border: 'none'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00024 4.37615V1H10.0003V4.37615H4.37527V15.6263H10.0003V19.0002H4.37527H1.00024V15.6263V4.37615ZM6.61036 7.7503H12.25V4.36739L19.0001 10.0003L12.25 15.6332V12.2503H6.61036V7.7503Z" fill="#0097E0"/>
                </svg>
                {'Log out'}
            </button>
        </div>
    )
}