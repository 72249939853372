import MyWork from "./pages/MyWork";
import { Historic } from "./pages/Historic";
import  Schedule  from "./pages/Schedule";
import { Dashboard } from "./pages/Dashboard";
import Drafthours from "./pages/Drafthours";
import ChangeOrders from "./pages/ChangeOrders";
import ReferenceOrders from "./pages/ReferenceOrders";
import ChangeOrderDetail from "./pages/ChangeOrderDetail";
import CodeSplit from "./pages/CodeSplit";
import CodeCompare from "./pages/CodeCompare";

const AppRoutes = [
  {
    index: true,
    element: <Schedule />
  },
  {
    path: '/changeorders',
    element: <ChangeOrders />
  },
  {
    path: '/drafthours',
    element: <Drafthours />
  },
  {
    path: '/referenceorders',
    element: <ReferenceOrders />
  },
  {
    path: '/my-work',
    element: <MyWork />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/historic',
    element: <Historic />
  },
  {
    path: '/changeorder/:orderDetailId/:esoi',
    element: <ChangeOrderDetail />
  },
  {
    path: '/codesplit/:orderDetailId/:esoi',
    element: <CodeSplit />
  },
  {
    path: '/codeCompare/:orderDetailId1/:esoi1/:orderDetailId2/:esoi2',
    element: <CodeCompare />
  }
];

export default AppRoutes;