import React from 'react';
import { SvgIcon } from "@progress/kendo-react-common";
import { circleIcon } from "@progress/kendo-svg-icons";

const ETOCell = (props) => {
  const { dataItem, field } = props;
  const isETO = field === 'etoproc';
  const isNote = field === 'engnoteComp';
  if (isETO && dataItem.etoproc) {
    return (
    <td style={{padding: "0px"}}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <SvgIcon icon={circleIcon} size={'xlarge'} color='#13CE66'></SvgIcon>
        </div>
    </td>  
    );
  } 
  else if(isNote && dataItem.itemType === 'FIN' && !dataItem.engnoteComp) {
    return (
    <td style={{padding: "0px"}}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <SvgIcon icon={circleIcon} size={'xlarge'} color='#DCDCDC'></SvgIcon>
        </div>
    </td>  
    );
  }
  else if(isNote && dataItem.engnoteComp) {
    return (
    <td style={{padding: "0px"}}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <SvgIcon icon={circleIcon} size={'xlarge'} color='#13CE66'></SvgIcon>
        </div>
    </td>  
    );
  }
  else {
    return (
    <td style={{padding: "0px"}}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <SvgIcon icon={circleIcon} size={'xlarge'} color='#FF4949'></SvgIcon>
        </div>
    </td>
    );
  }
};

export default ETOCell;