import React, { useState, useEffect, useCallback } from 'react';
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon} from "@progress/kendo-svg-icons";
import './SidePanel.css';
import { RadioButton } from "@progress/kendo-react-inputs";
import CustomDatePicker from './datePicker/CustomDatePicker';
import { Button } from '@progress/kendo-react-buttons';
import dayjs from 'dayjs';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import { getCurrentLine} from '../services/CurrentLineService';

const checkmark = (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.51875 8.49994L0.71875 4.69994L1.66875 3.74994L4.51875 6.59994L10.6354 0.483276L11.5854 1.43328L4.51875 8.49994Z" fill="white"/>
  </svg>
);


export const ChipsMAW = ({ endPointData, field, label, onChangeFilter, fetchOptions }) => {
  const [modelTypes, setModelTypes] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [show, setShow] = useState(fetchOptions ? true : false); 
  const [columnOptions, setColumnOptions] = useState(!fetchOptions ? endPointData : []);
  const { token } = useProfileData(false);

  const fetchFiltersData = async () => {
    if (token) {
        const response = await axios.get(
            `/Department/GetFilterOptions/${encodeURIComponent(getCurrentLine().ProdFamilyId)}?plantProdFamilyId=${encodeURIComponent(getCurrentLine().PlantProdFamilyId)}&columnNameDistinct=${encodeURIComponent(field)}&ViewOption=${encodeURIComponent(0)}`,            
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        setColumnOptions(response.data);            
    }
  };    

  useEffect(() => {    
    const fieldValues = (columnOptions.find(column => column.columnName === field)?.uniqueValues) ?? [];
    const updatedEndPoints = [
      { text: 'All', selected: true}, // Default the "All" option as selected
      ...fieldValues.map((value, index) => ({
        text: value,
        selected: false
      }))
    ];

    setModelTypes(updatedEndPoints);
    if (updatedEndPoints.length > 0) {
      setSelectedValue(updatedEndPoints[0].text);
      if (onChangeFilter) {
        onChangeFilter(field, updatedEndPoints[0].text);
      }
    }
  }, [field, onChangeFilter]);

  const handleOptionClick = useCallback(
    (index) => {
      const updatedOptions = modelTypes.map((option, i) => ({
        ...option,
        selected: i === index ? !option.selected : false
      }));

      // Ensure at least one option is always selected
      const isAnySelected = updatedOptions.some(option => option.selected);
      if (!isAnySelected) {
        updatedOptions[0].selected = true; // Default to the first option
      }

      setModelTypes(updatedOptions);

      // Update selected value
      const selectedOption = updatedOptions.find(option => option.selected);
      const newValue = selectedOption ? selectedOption.text : updatedOptions[0].text;
      setSelectedValue(newValue);

      // Call onChangeFilter if defined
      if (onChangeFilter) {
        onChangeFilter(field, newValue);
      }
    },
    [modelTypes, onChangeFilter, field]
  );

  const onClick = () => {
    setShow(!show);    

    if(show && fetchOptions)
      fetchFiltersData();      
  };

  const arrow = [
    <div><svg
    transform={show ? 'rotate(-180)' : ''}
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="#1C2325"
  >
    <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325" />
  </svg></div> ];

  const containerStyles = label !== 'none' ? {
    padding: '16px',
    background: '#FFF',
    height: 'inherit',
    width: 'inherit',
    filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.09))',
    borderBottom: '1px solid #CFDFDF'
  } : {};

  return (
    <div style={containerStyles}>
    { label !== 'none' && (
      <div style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className='roboto-15-700' style={{textAlign: 'left'}}>{label}</div>
        <button style={{background: 'transparent', width: '19px'}} onClick={onClick}>{arrow}</button>
      </div>
    )}
      {!(label !== 'none' && show) && (
      <div>
      {/* Initial filter */}
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
          <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className='roboto-15-400' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
              {modelTypes.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionClick(index)}
                  style={{
                    backgroundColor: option.selected ? '#0097E0' : 'transparent',
                    color: option.selected ? 'white' : 'inherit',
                    padding: '0px 10px 0px 10px',
                    margin: '8px 8px 8px 0px',
                    border: option.selected ?  '1px solid transparent' : '1px solid #EBEBEB',
                    borderRadius: '180px',
                    height: '24px',
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex', flexDirection: 'row'
                  }}
                >
                  {option.text}
                  <div style={{display: option.selected ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', marginLeft: '8px'}}>{checkmark}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
      )}
      {/* Initial filter */}
    </div>
  );
};

export const DateMAW = ({ field, label, onChangeFilter,
  onDateRangeChange, emptyDate, setEmptyDate,startDate, setStartDate, endDate,  setEndDate,
}) => {

  const [errorMessage, setErrorMessage] = useState('');

  // Define the callback using useCallback
  const handleDateChange = useCallback(() => {
    const newValue = { startDate, endDate };
    if (onChangeFilter) {      
      onChangeFilter(field, newValue);
    }
  }, [onChangeFilter, startDate, endDate]);

  // Use the callback inside useEffect
  useEffect(() => {
    handleDateChange();
  }, [startDate, endDate]);

  const handleStartDateSelect = (date) => {
    setStartDate(dayjs(date).startOf('week'));
    setEndDate(dayjs(date).endOf('week').subtract(1,'day'));
    setErrorMessage('');
    // onDateRangeChange(dayjs(date).startOf('week'), dayjs(date).endOf('week').subtract(1,'day'));
    setEmptyDate(false);
  };

  const handleEndDateSelect = (date) => {
    if (startDate && date.isBefore(startDate, 'day')) {
      setErrorMessage('The selected end week must start one or several weeks after the start week.');
    } else {
      setEndDate(dayjs(date).endOf('week').subtract(1,'day'));
      setErrorMessage('');
      // onDateRangeChange(startDate, dayjs(date).endOf('week').subtract(1,'day'));
      setEmptyDate(false);
    }
  };

  const clearDates = () => {
    // onChangeFilter(setStartDate(null), setEndDate(null));
    setStartDate(null);
    setEndDate(null);
    setEmptyDate(true);
  };

  return (
    <div style={{ padding: '16px', background: '#FFF', height: 'inherit', width: 'inherit', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.09))', borderBottom: '1px solid #CFDFDF'}}>
      <div className='roboto-15-700' style={{textAlign: 'left', marginBottom: '8px'}}>{label}</div>
        <div className= 'roboto-15-700' style={{flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between'}}>
          <div style={{background: '#EBEBEB', borderRadius: '4px'}}>
            <CustomDatePicker
              value={startDate}
              message={''}
              onDateSelect={handleStartDateSelect}
              endPicker= {false}
              empty= {startDate == null}
              MAWFilter = {true}
            />
          </div>
          {'to'}
          <div style={{background: '#EBEBEB', borderRadius: '4px'}}>
            <CustomDatePicker
              value={endDate}
              message={errorMessage}
              onDateSelect={handleEndDateSelect}
              endPicker= {true}
              empty= {endDate == null}
              MAWFilter = {true}
            />
          </div>
          <Button
            onClick={clearDates}
            style={{
                padding: '0px',
                width: '24px',
                height: '24px',
                borderRadius: '180px',
                backgroundColor: '#F2F2F2',
                border: 'none',
            }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
          </Button>
      </div>
    </div>
  );
};


export const RadioButtonMAW = ({ endPointData, field, label, onChangeFilter }) => {
  const fieldValues = (endPointData.find(column => column.columnName === field)?.uniqueValues) ?? [];
  const updatedEndPoints = fieldValues.map((value, index) => ({
    key: index,
    field: value
  }));

  const allOptions = updatedEndPoints;
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Older due date first");

  const onClick = () => {
    setShow(!show);
  };

  const handleChange = useCallback(
    (e) => {
      const newValue = e.value;
      setSelectedValue(newValue);
      if (onChangeFilter) {
        onChangeFilter(field, newValue);
      }
    },
    [onChangeFilter, field]
  );

  const arrow = (
    <div>
      <svg
        style={{ transform: show ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="#1C2325"
      >
        <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325" />
      </svg>
    </div>
  );

  return (
    <div style={{ padding: '16px', background: '#FFF', height: 'inherit', width: 'inherit', filter: 'drop-shadow(0px 5px rgba(0, 0, 0, 0.09))', borderBottom: '1px solid #CFDFDF' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className='roboto-15-700' style={{ textAlign: 'left' }}>{label}</div>
        <button style={{ background: 'transparent', width: '19px' }} onClick={onClick}>{arrow}</button>
      </div>
      {show && (
        <div>
          {allOptions.map((option) => (
            <div key={option.key} className='roboto-15-400' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '8px 0px' }}>
              {option.field}
              <RadioButton
                value={option.field}
                checked={selectedValue === option.field}
                // label={option.field}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
