import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ReferenceOrdersGrid from './ReferenceOrdersGrid';
import { Generalcolumns, ReferenceOrdersColumns, CancelledShippedColumns } from './Columns';
import './TabStripComponent.css'
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import { Button } from 'reactstrap';
import ColumnSelectorDialog from './ColumnSelectorDialog';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import { useLocation } from 'react-router-dom';

const TabComponentRefOrders = () => {
  const [selected, setSelected] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...ReferenceOrdersColumns]);

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });  
  const { token } = useProfileData(false);

  const location = useLocation();
  const formatNumber = location.state?.formatNumber;
  const selectedItems = location.state?.selectedItems || [];

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const [removeFilter, setRemoveFilter] = useState(false)
  const [dropdownFilters, setDropdownFilters] = useState([])

   //Get available model types for rooftoop
   const [modelTypes, setModelTypes] = useState([]);
   useEffect(() => {

    fetchModelTypeData();

  }, [token]);

  const fetchModelTypeData = async () => {
    try {
            
      if (token) {
        const response = await axios.get(
            `/selectionsByCodeNum?&codeNumber=${encodeURIComponent(1)}&formatNumber=${encodeURIComponent(formatNumber)}`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
          //console.log("Model types: ",response.data);
          // const responseModelTypes = response.data.map(item => item.cV_OPTION);
          const responseModelTypes = response.data.map(item => ({
            text: item.cvOption,
            value: item.cvOption,
            selected: false, 
          }));          
          
          setModelTypes(responseModelTypes);
    }
    } catch (error) {
      // setToast({ showToast: true, message: error.message, type: 'error' });
    }
  };


  const handleSelect = (e) => {
    setSelected(e.selected);
    switch (e.selected) {
      case 0:
        setSelectedColumns([...ReferenceOrdersColumns]);
        handleResetTableFilters();
        break;
      case 1:
        setSelectedColumns([...ReferenceOrdersColumns]);
        handleResetTableFilters();
        break;
      case 2:
        setSelectedColumns([...CancelledShippedColumns]);
        handleResetTableFilters();
        break;
      default:
        break;
    }
  };

  let allColumnss;
  switch (selected) {
      case 0:
          allColumnss = ReferenceOrdersColumns;
          break;
      case 1:
          allColumnss = ReferenceOrdersColumns;
          break;
      case 2:
          allColumnss = CancelledShippedColumns;
          break;
      default:
          allColumnss = Generalcolumns;
          break;
  }

  const handleSaveColumns = (columnField) => {        
    setSelectedColumns(prevSelectedColumns => {
      return prevSelectedColumns.map(column => {
        if (column.field === columnField) {
          return { ...column, visible: !column.visible };
        }
        return column;
      });
    });    
  };

  const handleResetColumns = () => {
    setSelectedColumns([...ReferenceOrdersColumns]);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const [filter, setFilter] = useState(null);
  const [prevFilter, setPrevFilter] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyDate, setEmptyDate] = useState(startDate !== null);

  const handleResetTableFilters = () => {
    setFilter(null);
    setPrevFilter([]);
    setCurrentFilter([]);
  };

  const handleResetFilter = () => {
    handleResetTableFilters();
    setStartDate(null);
    setEndDate(null);
    setDateRange({ startDate: null, endDate: null });
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    setEmptyDate(true);
  }

  const [size, setSize] = React.useState("medium");
  const [fillMode, setFillMode] = React.useState("solid");
  const [rounded, setRounded] = React.useState("full");
  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate", "loadDate"];

  const groupedFilters = filter && filter.filters ? filter.filters.reduce((acc, curr) => {
    if (!acc[curr.field]) {
      acc[curr.field] = [];
    }

    let formattedValue = curr.value;
    if (typeof curr.value === 'boolean') {
      // formating boolean value
      formattedValue = curr.value ? "Yes" : "No";
    } else if (dateFields.includes(curr.field)) {
      // formating date value 
      formattedValue = new Date(curr.value) instanceof Date && !isNaN(new Date(curr.value))
        ? new Date(curr.value).toLocaleDateString('en-US')
        : curr.value;
    }

    acc[curr.field].push(formattedValue);
    return acc;
  }, {}) : {};

  const clearFilter = (field) => {    
    handleRemoveAllFilters(field);    
  };
    
  const extraData = { filtersLabel: ["Active filters:"]};

  const chipsData = {
    ...extraData,
    ...groupedFilters
  };

  
  const handleRemoveAllFilters = (currentFilterField) => {
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }

      // Remove the filter related to the current custom filter field
      const updatedFilters = prevFilter.filters.filter((filter) => filter.field !== currentFilterField);

      // If there are no filters left, return null to clear the filter
      return updatedFilters.length > 0 ? { ...prevFilter, filters: updatedFilters } : null;
    });

    setRemoveFilter(true);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    const updatedFields = allFields.filter(item => item !== currentFilterField);
    const allFields2 = [...new Set(updatedFields)];
    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters);
  };

  const [disableColumnsSelector, setDisableColumnsSelector] = useState(true);
  const [exportExcel, setExportExcel] = useState(false);
  const [initialFilterTitle, setInitialFilterTitle] = useState("");

  const handleOptionClick = (index) => {
    const updatedOptions = modelTypes.map((option, i) => ({
      ...option,
      selected: i === index ? !option.selected : option.selected
    }));    
    setModelTypes(updatedOptions);    
  };

  const checkmark = (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.51875 8.49994L0.71875 4.69994L1.66875 3.74994L4.51875 6.59994L10.6354 0.483276L11.5854 1.43328L4.51875 8.49994Z" fill="white"/>
    </svg>
  );

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px'}}>
      {/* Display ColumnSelectorDialog */}
      {(selected === 0 ) && (
        <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px'}}>
          <button
            className="roboto-15-500 secondary-button blue-text"
            onClick={() => setDialogVisible(true)}
            style={{ gap: '8px', width: 'fit-content', flexDirection: 'row', display: 'flex', alignItems: 'center' }}
            disabled={disableColumnsSelector}
          >
            {/* {'Columns'}<img className="settings-icon1 ml-5" alt="" src="/settings1.svg" /> */}
            {'Columns'}
            <img
              className={`settings-icon1 ml-5 ${disableColumnsSelector ? 'disabled-icon' : ''}`}
              alt=""
              src="/settings1.svg"
            />
          </button>
          <ColumnSelectorDialog
            visible={dialogVisible}
            onClose={handleDialogClose}
            onSave={handleSaveColumns}
            selectedColumns={selectedColumns}
            allColumns={allColumnss}
            onReset={handleResetColumns}
          />
        </div>
      )}
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ border: 'none' }}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
          <div style={{margin: '16px',  display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
              <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
                <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div className='roboto-15-400' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                    {initialFilterTitle}:
                    {modelTypes.map((option, index) => (
                      <button
                        key={index}
                        onClick={() => handleOptionClick(index)}
                        style={{
                          backgroundColor: option.selected ? '#0097E0' : 'transparent',
                          color: option.selected ? 'white' : 'inherit',
                          padding: '0px 10px 0px 10px',
                          margin: '8px',
                          border: option.selected ?  '1px solid transparent' : '1px solid #EBEBEB',
                          borderRadius: '180px',
                          height: '24px',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex', flexDirection: 'row'
                        }}
                      >
                        {option.text}
                        <div style={{display: option.selected ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', marginLeft: '8px'}}>{checkmark}</div>
                      </button>
                      
                    ))}
                  </div>
                </div>
              </div>
          </div>
          {/* Active filters chips section*/}
          {Object.keys(chipsData).length > 1 && ( 
            <div className='chipListContainer'> 
            <div className='d-flex align-items-center flex-wrap'>
            <div className="chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap">
          
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={chipsData[props.dataItem].join(', ')}>                        
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(selectedColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(selectedColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (selectedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(selectedColumns.find(col => col.field === props.dataItem)) ? (selectedColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>
                              <span className='tabSplit' style={{ display: (selectedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem].slice(0, 10).join(', ')}
                                {chipsData[props.dataItem].length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}
          
          {modelTypes.some(option => option.selected)
            ?   <div style={{visibility: 'initial' }}>

            <ReferenceOrdersGrid 
              columns={selectedColumns} 
              dateRange={dateRange} 
              filter={filter} 
              setFilter={setFilter}
              prevFilter={prevFilter}
              setPrevFilter={setPrevFilter}
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              disabled={true}
              setDisableColumnsSelector={setDisableColumnsSelector}
              onRemoveAll={handleRemoveAllFilters}            
              removeFilter={removeFilter}
              setRemoveFilter={setRemoveFilter}
              setDropdownFilters={setDropdownFilters}
              dropdownFilters={dropdownFilters}
              setInitialFilterTitle={setInitialFilterTitle}
              exportExcel={exportExcel}
              setExportExcel={setExportExcel}
              modelTypes={modelTypes}       
              selectedItems={selectedItems}
              formatNumber={formatNumber}              
            />
            </div>
            : <div style={{visibility: 'hidden' }}>

            <ReferenceOrdersGrid 
              columns={selectedColumns} 
              setColumns={setSelectedColumns}
              dateRange={dateRange} 
              filter={filter} 
              setFilter={setFilter}
              prevFilter={prevFilter}
              setPrevFilter={setPrevFilter}
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              disabled={true}
              setDisableColumnsSelector={setDisableColumnsSelector}
              onRemoveAll={handleRemoveAllFilters}            
              removeFilter={removeFilter}
              setRemoveFilter={setRemoveFilter}
              setDropdownFilters={setDropdownFilters}
              dropdownFilters={dropdownFilters}
              setInitialFilterTitle={setInitialFilterTitle}
              exportExcel={exportExcel}
              setExportExcel={setExportExcel}
              modelTypes={modelTypes}          
              selectedItems={selectedItems}
              formatNumber={formatNumber}           
            />
            </div>
          }
          

        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default TabComponentRefOrders;
