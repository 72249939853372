import React from 'react';
import TabStripComponent from '../components/TabStripComponent';

const Schedule = () => {
  return (
    <div style={{marginTop: '10px'}}>
      <TabStripComponent></TabStripComponent>
    </div>
  );
};

export default Schedule;