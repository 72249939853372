import React from 'react';
import './FilterDropDown.css';

const FilterSection = ({ title, options, selectedOptions, onCheckboxChange, filter, setSelectedOptions }) => {

    const handleUnmarkSection = (filter) => {
        const updatedOptions = selectedOptions.filter(item => item.fiCodeItem !== filter.fiCodeItem);
        setSelectedOptions(updatedOptions);
    };

    return (
        <div className="section">
            <div className="section-header mt-3">
                <span>{title}</span>
                <button className="icon-trash" onClick={() => handleUnmarkSection(filter)}>
                    <img className="icon-image" alt="Edit Trash" src="/edit-trash.png" />
                </button>
            </div>
            <div className="checkbox-list mt-2">
                {options.map((option, index) => (
                    <div key={`${option}-${index}`} className="checkbox-item fs-15">
                        <input
                            className="k-checkbox k-checkbox-md k-rounded-md check-border"
                            type="checkbox"
                            value={option}
                            checked={selectedOptions.some(item => item.Value === option && item.fiCodeItem === filter.fiCodeItem)}
                            onChange={onCheckboxChange}
                        />
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterSection;
