import React from 'react';
import TabComponentRefOrders from '../components/TabComponentRefOrders';

const ReferenceOrders = () => {
  return (
    <div style={{marginTop: '10px'}}>
      <TabComponentRefOrders></TabComponentRefOrders>
    </div>
  );
};

export default ReferenceOrders;